import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { NavLink, Link, useLocation } from "react-router-dom";

const ProviderList = (props) => {
    const location = useLocation();
    const [providerList, setProviderList] = useState([]);
    const queryParams = new URLSearchParams(location.search);
    const encodedData = queryParams.get("data");
    const categoryCode = queryParams.get("menu_code");

    useEffect(() => {
        if (encodedData) {
            const decodedData = JSON.parse(decodeURIComponent(encodedData));
            const providers = [];
            Object.values(decodedData).forEach(category => {
                providers.push(...Object.values(category));
            });
            setProviderList(providers);
        }
    }, [encodedData]);

    const uniqueProviderNames = new Set();
    const uniqueProviders = providerList.map((data, index) => {
        const providerName = data?.providerDetails?.providerInfo?.name;

        if (data.menu_code === categoryCode && providerName && !uniqueProviderNames.has(providerName)) {
            uniqueProviderNames.add(providerName);
            return (
                <li key={providerName}>
                    <Link to={`/game-list?category_code=${categoryCode}&provider_name=${providerName}`}>{providerName}</Link>
                </li>
            );
        }

        return null;
    }).filter(Boolean);  // Filter out null values

    return (
        <>
            <div>
                <h5><NavLink to='/'>{props.t('back')}</NavLink></h5>
                <h2>{props.t('provider_list')}</h2>
            </div>
            <ul>
                {uniqueProviders}
            </ul>
        </>
    );
};

ProviderList.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(ProviderList);
