import React, { useEffect, useState } from "react";
import { HiOutlineMail } from "react-icons/hi"
import { NavLink } from 'react-router-dom'
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getPlayerProfileDetail } from "../../store/player-profile/actions";

const EmailVerification = (props) => {


    const dispatch = useDispatch();
    const [hasVerificationEmailSent, setHasVerificationEmailSent] = useState(false);
    const [formError, setFormError] = useState({});
    const [isEmailVerified, setIsEmailVerified] = useState(false)
    const [formData, setFormData] = useState({
        email_id: ""
    })
    const [popUpData, setPopUpData] = useState({
        email_id: '',
        please_check: ''
    });


    const { userProfile } = useSelector(state => ({
        userProfile: state.playerProfileR.data
    }));

    useEffect(() => {
        dispatch(getPlayerProfileDetail());
    }, []);

    const checkEmailVerified = () => {
        let verified_email = false;
        if (userProfile?.email_verified_at)
            verified_email = true;
        setIsEmailVerified(verified_email);
    }

    useEffect(() => {
        checkEmailVerified();
    }, [userProfile]);

    const verifyOnEmailSubmit = (e) => {
        e.preventDefault();
        setPopUpData({});
        makeAjax(
            url.USER_API.emailVerification,
            formData,
            url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error')
                        showMessage(response);
                    setFormError(response.messages);
                    // setIsErrorMessagePopUp(true);
                    return;
                }
                setFormError({});
                setPopUpData(response.data)
                setFormData({ ...formData, ...{ email_id: '' } });
                setHasVerificationEmailSent(true);
                dispatch(getPlayerProfileDetail());
            });
    }

    const changeEmail = () => {
        setIsEmailVerified(true);
    }

    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value })
        setFormError({ ...formError, [event.target.name]: '' })
    }


    return (
        <div>
            {!isEmailVerified && !hasVerificationEmailSent && <div>
                <NavLink to='/profile'>{props.t('back')}</NavLink>
                <h4>{props.t('email_verification')}</h4>

                <div>
                    <form onSubmit={verifyOnEmailSubmit}>
                        <div>
                            <input type="email" name="email_id" placeholder={props.t('enter_your_email')} onChange={handleChange} />
                            <br />
                            {formError.email_id ? (
                                <span style={{ color: 'red' }}>{formError.email_id[0]}</span>
                            ) : null}
                        </div>
                        <br />
                        <button type="submit">{props.t('process')}</button>

                    </form>
                    <br />
                    {/* <NavLink onClick={() => { changeEmail() }}>{props.t('change_email')} ?</NavLink> */}
                    {/* <a href="#" onClick={changeEmail}>{props.t('change_email')}?</a> */}
                </div>
            </div>
            }
            {hasVerificationEmailSent ? (
                <>
                    <div>
                        <div>
                            <HiOutlineMail />
                            <p>{popUpData.verification_link} <br />{popUpData.email_id}, {popUpData.please_check}</p>
                            <button><NavLink to="/profile">{props.t('ok')}</NavLink></button>
                        </div>
                    </div>
                </>
            ) : null}

        </div>

    )
}

EmailVerification.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default (withTranslation()(EmailVerification)); 