import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper";
import { useNavigate } from 'react-router-dom'
import { getLogout } from "../../store/logout/action";
import { useDispatch } from "react-redux";

const SecurityQuestion = props => {

    const [formData, setFormData] = useState({
        security_question_id: '',
        security_answer: ''
    });
    const [userProfile, setUserProfile] = useState({ username: '' });
    const [securityDD, setSecurityList] = useState([]);
    const [formError, setFormError] = useState({});
    const [getData, setData] = useState({})
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const getPlayerProfileDetail = () => {
        makeAjax(
            url.USER_API.getPlayerProfileDetail, [], url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    showMessage(response);
                    return;
                }
                setUserProfile(response.data);
            });
    };

    const getSecurityList = () => {
        makeAjax(
            url.USER_API.securityQuestionDD, [], url.USER_MS_EXT).then(response => {
                 
                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response);
                    return;
                }
                setSecurityList(response.data);
            });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        makeAjax(
            url.USER_API.addUpdatePlayerSecurityQuestion, formData, url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    setFormError(response.data);
                    return;
                }

                formData['security_question_id'] = "";
                formData['security_answer'] = "";
                setFormData({
                    ...formData,
                });

                showMessage(response);
                getPinStatus();
                if (getData.enabled_active_pin === true) {
                    navigate("/security-question");
                } else {
                    navigate("/");
                }
            });
    }


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setFormError({});
    }

    const cancelSubmit = (e) => {
        e.preventDefault();
        dispatch(getLogout());
        navigate('/');
    }


    const getPinStatus = () => {
        makeAjax(
            url.USER_API.checkIfPinAlreadySet, [], url.USER_MS_EXT).then(response => {
                let config_status = response.data.get_tenant_config.value;

                if (response.data.is_reset === true) {
                    logout();
                    return;
                }

                setData(response.data)
                if (config_status === true) {
                    if (response?.data?.enabled_active_pin === true && response?.data?.active_pin === false) {
                        // if (response.data.active_pin === '' || response.data.active_pin === null && response.data.enabled_active_pin === true) {
                        navigate("/create-pin");
                        return;
                    }
                    else if (response?.data?.enabled_active_pin === true && response?.data?.is_pin_verified === false) {
                        navigate("/validation-pin");
                        return;
                    }
                    else if ((response?.data?.is_security_question_set === false || response?.data?.is_security_question_set === null)) {
                        navigate("/security-question");
                        return;
                    }
                    else {
                        navigate("/");
                        return;
                    }
                } else {
                    navigate("/");
                }
            });
    }

    const logout = () => {
        makeAjax(
            url.USER_API.logout, [], '').then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response);
                    return;
                }
            });
        window.localStorage.removeItem("user_wap_app_token");
        navigate('/');
    };

    useEffect(() => {
        getSecurityList();
        getPinStatus();
        getPlayerProfileDetail();
    }, []);

    return (
        <div>
            <div>
                <h4>{props.t('welcome')}, {userProfile?.username}</h4>
                <div>
                    <p>{props.t('security_questions_and_answers')}</p>
                    <ul>
                        <li>{props.t('please_select_a_question_and_enter_the_answer_that_best_suits_you')}. </li>
                        <li>{props.t('the_security_answer_column_cannot_be_empty_must_be_filled_in_with_at_least_3_characters')}</li>
                        <li>{props.t('this_security_answer_will_be_needed_when_using_the_forgot_password_menu')}.</li>
                    </ul>
                    <form>
                        <div>
                            <label>{props.t('select_security_question')} </label>
                            <select
                                type="select"
                                value={formData.security_question_id}
                                onChange={handleChange}
                                name="security_question_id"
                                placeholder={props.t('security_question')}
                            >
                                <option value='select'>---{props.t('select')}---</option>
                                {securityDD && securityDD.map((data, index) => {
                                    return (
                                        <option value={data._id} key={index}>{data.question}</option>
                                    )
                                })}
                            </select>
                        </div>
                        {formError.security_question_id ? (
                            <span style={{ color: 'red' }}>{formError.security_question_id}</span>
                        ) : null}
                        <div>
                            <label>{props.t('security_answer')} : </label>
                            <input type="text" placeholder={props.t('security_answer')} name="security_answer" value={formData.security_answer} onChange={handleChange} style={{ color: "black" }} />
                            <br/>{formError.security_answer ? (
                                <span style={{ color: 'red' ,marginLeft:'120px'}}>{formError.security_answer[0]}</span>
                            ) : null}
                        </div>
                        <span>*{props.t('please_remeber_the_answer_to_your_security_question_a_security_queestion_will_be_asked_when_you_forgot_your_password')}</span>
                        <div>
                            <button type="button" onClick={(e) => { handleSubmit(e) }}>{props.t('send')}</button>
                            <button type="button" onClick={(e) => { cancelSubmit(e) }}>{props.t('log_out')}</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    )
}

SecurityQuestion.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(SecurityQuestion);
