//REGISTER

export const API_PREFIX = "/api";
export const APPLICATION_EXT = "/user";

export const MASTER_MS_EXT = "/master";
export const ADMIN_MS_EXT = "/admin";
export const USER_MS_EXT = "/user";
export const LOG_MS_EXT = "/log";
export const GP_MS_EXT = "/gp";
export const MEMO_MS_EXT = "/memo";
export const DPWD_MS_EXT = "/dpwd";

export const COMMON_API = {
    login: '/user/login',
    loadCaptcha: '/user/loadCaptcha',
    isLoggedIn: '/user/isLoggedIn',
    logout: '/user/logout',
    me: '/user/me',
};

export const MASTER_API = {
    getOperatorProfile: '/getOperatorProfile',
    updateMyPassword: '/updateMyPassword',
    operatorDD: '/operatorDD',
    countryDD: '/countryDD',
    languageDD: '/languageDD',
    timezoneDD: '/timezoneDD',
    listLanguageData: '/listLanguageData',
    getInternalNews: '/getInternalNews',
    currencyDD: '/currencyDD',
    durationDD: '/durationDD',
    templateDD: '/templateDD',
    subtemplateDD: '/subtemplateDD',
    levelDD: '/levelDD',
    addLevel: '/addLevel',
    updateLevel: '/updateLevel',
    listLevel: '/listLevel',
    deleteLevel: '/deleteLevel',
    listAllPermission: '/listAllPermission',
    getLevel: '/getLevel',
    listAccessibleMenu: '/listAccessibleMenu',
    addUpdateInternalNews: '/addUpdateInternalNews',
    listOperator: '/listOperator',
    addOperator: '/addOperator',
    massUpdateOperator: '/massUpdateOperator',
    updateOperatorPassword: '/updateOperatorPassword',
    massDeleteOperator: '/massDeleteOperator',
    cssClassDD: '/cssClassDD',
    getClassCss: '/getClassCss',
    getSubTemplateCSS: '/getSubTemplateCSS',
    addClassCss: '/addClassCss',
    updateClassCss: '/updateClassCss',
    getGlobalProviderMaintenance: "/getGlobalProviderMaintenance",
    addUpdateGlobalProviderMaintenance: "/addUpdateGlobalProviderMaintenance",
    addUpdateConsumerComplain: '/addUpdateConsumerComplain',
    getConsumerComplain: '/getConsumerComplain',
    addAllowedIP: '/addAllowedIP',
    listAllowedIP: '/listAllowedIP',
    deleteAllowedIP: '/deleteAllowedIP',
    updatePlayerSetting: '/updatePlayerSetting',
    getPlayerSetting: '/getPlayerSetting',
    updateMailTemplate: '/updateMailTemplate',
    getMailTemplate: '/getMailTemplate',
    getTenantTemplateCSS: '/getTenantTemplateCSS'
};

export const ADMIN_API = {
    login: '/login',
    loadCaptcha: '/loadCaptcha',
    isLoggedIn: '/isLoggedIn',
    logout: '/logout',
    listAvailableLimitWarning: '/listAvailableLimitWarning',
    massUpdateAvailableLimit: '/massUpdateAvailableLimit',
    massDeleteAvailableLimitWarning: '/massDeleteAvailableLimitWarning',
    addClient: '/addClient',
    updateClient: '/updateClient',
    listClient: '/listClient',
    partnerDD: '/partnerDD',
    clientDD: '/clientDD',
    clientGroundLevelDD: '/clientGroundLevelDD',
    clientColumnDD: '/clientColumnDD',

    // Client limit API
    resetClientAvailableLimit: '/resetClientAvailableLimit',
    listClientAvailableLimit: '/listClientAvailableLimit',
    manualResetClientAvailableLimit: '/manualResetClientAvailableLimit',
    listClientTotalAvailableLimit: '/listClientTotalAvailableLimit',
    resetClientTotalLimit: '/resetClientTotalLimit',
    listClientUpdateLimitHistory: '/listClientUpdateLimitHistory',
    getGameInfo: '/getGameInfo',
    addUpdateGameInfo: '/addUpdateGameInfo',
    // Allowed Ip
    listAllowedIP: '/listAllowedIP',
    addAllowedIP: '/addAllowedIP',
    deleteAllowedIP: '/deleteAllowedIP',
    listGameSetting: '/listGameSetting',
    updateTenentVendorGame: '/updateTenentVendorGame',
    levelDD: '/levelDD',
    listOperator: '/listOperator',
    addOperator: '/addOperator',
    massUpdateOperator: '/massUpdateOperator',
    updateOperatorPassword: '/updateOperatorPassword',
    gameDD: '/gameDD',
    addUpdateHotGameList: '/addUpdateHotGameList',
    addUpdateAutoGameSetting: '/addUpdateAutoGameSetting',
    getAutoGameSetting: '/getAutoGameSetting',
    listControlApp: '/listControlApp',
    addUpdateMobileApp: '/addUpdateMobileApp',
    getLiveChatControl: '/getLiveChatControl',
    addUpdateLiveChatControl: '/addUpdateLiveChatControl',
    getClientDetails: '/getClientDetails',
    getRule: '/getRule',
    promotionCategoryNameDD: '/promotionCategoryNameDD'
};

export const USER_API = {
    login: '/user/login',
    loadCaptcha: '/loadCaptcha',
    listControlPassword: '/listControlPassword',
    deleteControlPassword: '/deleteControlPassword',
    addControlPassword: '/addControlPassword',
    isLoggedIn: '/isLoggedIn',
    logout: '/user/logout',
    listReservedWord: '/listReservedWord',
    deleteReservedWord: '/deleteReservedWord',
    addReservedWord: '/addReservedWord',
    listAlternativeSite: '/listAlternativeSite',
    deleteAlternativeSite: '/deleteAlternativeSite',
    addAlternativeSite: '/addAlternativeSite',
    updateAlternativeSite: '/updateAlternativeSite',
    verifyWithEmail: '/verifyWithEmail',
    validateBankDetails: '/validateBankDetails',
    verifyWithBankDetails: '/verifyWithBankDetails',
    verifiedToken: '/verifiedToken',
    verifyToken: '/verifyToken',
    verifyEmailToken: '/verifyEmailToken',
    changeEmail: '/changeEmail',
    activePin: '/activePin',
    checkToken: '/checkToken',

    // Block Account URL
    listBlockedAccount: '/listBlockedAccount', // listBlockedAccount is End point of api
    deleteBlockedAccount: '/deleteBlockedAccount', // deleteBlockedAccount is End point of api
    addBlockedAccount: '/addBlockedAccount', // addBlockedAccount is End point of api
    bankDD: '/bankDD', // bankDD is End point of api
    getBankLengthValidation: '/getBankLengthValidation', // bankDD is End point of api

    addControlPin: '/addControlPin',
    deleteControlPin: '/deleteControlPin',
    updateControlPin: '/updateControlPin',
    listControlPin: '/listControlPin',

    listTenantUserMenu: '/listTenantUserMenu',
    addTenantUserMenu: '/addTenantUserMenu',
    listSearchPlayer: '/listSearchPlayer',
    addUpdatePlayerRemark: '/addUpdatePlayerRemark',
    listActivePlayer: '/listActivePlayer',
    addAllowedIP: '/addAllowedIP',
    listAllowedIP: '/listAllowedIP',
    updateAllowedIP: '/updateAllowedIP',
    deleteAllowedIP: '/deleteAllowedIP',
    securityQuestionDD: '/securityQuestionDD',
    userRegistration: '/userRegistration',
    userProfile: '/userProfile',
    changePassword: '/changePassword',
    getBankValidationCode: '/getBankValidationCode',
    emailVerification: '/emailVerification',
    getPlayerProfileDetail: '/getPlayerProfileDetail',
    sendPlayerMessage: '/sendPlayerMessage',
    playerOutboxMessageList: '/playerOutboxMessageList',
    listPlayerAlternativeSite: '/listPlayerAlternativeSite',
    setUserNewPin: '/setUserNewPin',
    checkIfPinAlreadySet: '/checkIfPinAlreadySet',
    checkValidatePin: '/checkValidatePin',
    reminderPassword: '/reminderPassword',
    oldPassword: '/oldPassword',
    addUpdatePlayerSecurityQuestion: '/addUpdatePlayerSecurityQuestion',
    listTenantBank: '/listTenantBank',
    listWapUserSubMenu: '/listWapUserSubMenu',
    listWapProviderGame: '/listWapProviderGame',
    updateMyLanguage: '/updateMyLanguage',
    playerBankDD: '/playerBankDD'
};

export const LOG_API = {
    listOperatorLog: '/listOperatorLog',
    logTypeDD: '/logTypeDD'
};

export const GP_API = {
    listMaintenance: '/listMaintenance',
    addUpdateMaintenance: '/addUpdateMaintenance',
    categoryGameDD: '/categoryGameDD',
    gameCategoryDD: '/gameCategoryDD',
    gameTypeDD: '/gameTypeDD',
    providerDD: '/providerDD',
    addUpdateTenantProvider: '/addUpdateTenantProvider',
    gameGategoryProviderDD: '/gameGategoryProviderDD',
    tenantProviderDD: '/tenantProviderDD',
    providerGameTypeDD: '/providerGameTypeDD',
    playerRedirection: '/playerRedirection',
    getBalance: '/getBalance',
    transferIn: '/transferIn'
};

export const MEMO_API = {
    // WL2-1036
    listConsumerComplaint: '/listConsumerComplaint',
    getConsumerComplaint: '/getConsumerComplaint',
    listConsumerCategory: '/listConsumerCategory',

    addConsumerCategory: '/addConsumerCategory',
    updateConsumerCategory: '/updateConsumerCategory',
    deleteConsumerCategory: '/deleteConsumerCategory',
    getConsumerCategory: '/getConsumerCategory',
    getPlayerUnreadMessageCount: '/getPlayerUnreadMessageCount',
    playerComposeMessage: '/playerComposeMessage',
    listPlayerInboxMessage: '/listPlayerInboxMessage',
    deletePlayerMessage: '/deletePlayerMessage',
    listPlayerOutboxMessage: '/listPlayerOutboxMessage',
    playerUpdateMessageStatus: '/playerUpdateMessageStatus',
    sendPlayerMessage: '/sendPlayerMessage'
}

export const MASTER_LOGIN = "/login";

export const DPWD_API = {
    addWithdrawRequest: '/addWithdrawRequest',
    checkPendingTransaction: '/checkPendingTransaction',
    getBankDetail: '/getBankDetail',
    depositAmount: '/depositAmount',
    getDepostListTen: '/getDepostListTen',
    depositWithdrawReadMessage: '/depositWithdrawReadMessage',
    depositWithdrawNotificationMessage: '/depositWithdrawNotificationMessage',
    checkWithdrawPendingRequest: '/checkWithdrawPendingRequest'
}
//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//PRODUCTS
export const GET_PRODUCTS = "/products";
export const GET_PRODUCTS_DETAIL = "/product";

//Mails
export const GET_INBOX_MAILS = "/inboxmails";
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail";
export const DELETE_INBOX_MAIL = "/delete/inboxmail";

//starred mail
export const GET_STARRED_MAILS = "/starredmails";

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails";

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails";

//Send mail
export const GET_SENT_MAILS = "/sentmails";

//Trash mail
export const GET_TRASH_MAILS = "/trashmails";

//CALENDER
export const GET_EVENTS = "/events";
export const ADD_NEW_EVENT = "/add/event";
export const UPDATE_EVENT = "/update/event";
export const DELETE_EVENT = "/delete/event";
export const GET_CATEGORIES = "/categories";

//CHATS
export const GET_CHATS = "/chats";
export const GET_GROUPS = "/groups";
export const GET_CONTACTS = "/contacts";
export const GET_MESSAGES = "/messages";
export const ADD_MESSAGE = "/add/messages";

//ORDERS
export const GET_ORDERS = "/orders";
export const ADD_NEW_ORDER = "/add/order";
export const UPDATE_ORDER = "/update/order";
export const DELETE_ORDER = "/delete/order";

//CART DATA
export const GET_CART_DATA = "/cart";

//CUSTOMERS
export const GET_CUSTOMERS = "/customers";
export const ADD_NEW_CUSTOMER = "/add/customer";
export const UPDATE_CUSTOMER = "/update/customer";
export const DELETE_CUSTOMER = "/delete/customer";

//SHOPS
export const GET_SHOPS = "/shops";

//CRYPTO
export const GET_WALLET = "/wallet";
export const GET_CRYPTO_ORDERS = "/crypto/orders";

//INVOICES
export const GET_INVOICES = "/invoices";
export const GET_INVOICE_DETAIL = "/invoice";

// JOBS
export const GET_JOB_LIST = "/jobs";
export const ADD_NEW_JOB_LIST = "/add/job";
export const UPDATE_JOB_LIST = "/update/job";
export const DELETE_JOB_LIST = "/delete/job";

//Apply Jobs
export const GET_APPLY_JOB = "/jobApply";
export const DELETE_APPLY_JOB = "add/applyjob";

//PROJECTS
export const GET_PROJECTS = "/projects";
export const GET_PROJECT_DETAIL = "/project";
export const ADD_NEW_PROJECT = "/add/project";
export const UPDATE_PROJECT = "/update/project";
export const DELETE_PROJECT = "/delete/project";

//TASKS
export const GET_TASKS = "/tasks";

//CONTACTS
export const GET_USERS = "/users";
export const GET_USER_PROFILE = "/user";
export const ADD_NEW_USER = "/add/user";
export const UPDATE_USER = "/update/user";
export const DELETE_USER = "/delete/user";

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data";
export const GET_YEARLY_DATA = "/yearly-data";
export const GET_MONTHLY_DATA = "/monthly-data";

export const TOP_SELLING_DATA = "/top-selling-data";

export const GET_EARNING_DATA = "/earning-charts-data";

export const GET_PRODUCT_COMMENTS = "/comments-product";

export const ON_LIKNE_COMMENT = "/comments-product-action";

export const ON_ADD_REPLY = "/comments-product-add-reply";

export const ON_ADD_COMMENT = "/comments-product-add-comment";