import { useState, useEffect } from "react"
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper"
import { useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

const RuleOfPlay = (props) => {
    const navigate = useNavigate()

    const [textData, setTextData] = useState({});

    const getRules = () => {
        makeAjax(
            url.ADMIN_API.getRule, { 'code': 'rule' }, url.ADMIN_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response);
                    return;
                }
                if (typeof response.data != null && response.data.value != '') {
                    setTextData(response.data);
                }
            });
    }


    const handleSubmit = (e) => {
        e.preventDefault()
        navigate("/validation-pin");
        return;
    }

    useEffect(() => {
        getRules();
    }, []);

    return (
        <div>
            <div>
                <h4>{props.t('rules_of_play')}</h4>
                <div style={{ marginLeft: '15px' }}>
                    <p><div dangerouslySetInnerHTML={{ __html: textData.value }}></div></p>

                    <form onSubmit={handleSubmit} >
                        <button style={{ height: '30px', width: '60px' }}>{props.t('agree')}</button>
                    </form>
                    <br /><br />
                </div>
            </div>
        </div>
    )
}

RuleOfPlay.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(RuleOfPlay);