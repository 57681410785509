import React, { useEffect, useState } from 'react'
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper";
import { NavLink } from 'react-router-dom';

const ForgotPassword = (props) => {

    const [formData, setFormData] = useState({});
    const [formError, setFormError] = useState({});
  
    const onChangeForm = (event) => {
        let name = event.target.name;
        let val = event.target.value;
        if (name == 'account_number') {
            if (formData['bank_id'] != '' && val.length) {
                val = val.substring(0, formData['validation_length']);
            } else {
                val = String(parseInt(val));
            }
            if (isNaN(val))
                val = '';
        }

        setFormError({ ...formError, [name]: '' });
        setFormData({ ...formData, [name]: val });
    }


    const verifyOnEmailSubmit = (event) => {
        makeAjax(
            url.USER_API.verifyWithEmail, formData, url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error')
                        showMessage(response);
                    setFormError(response.messages);
                    return;
                }
                setFormError({});
                setFormData({ ...formData, ...{ email_id: '' } });
                showMessage(response);
            });

    }

    return (
        <>
            <h5><NavLink to='/'>{props.t('back')}</NavLink></h5>
            <h2>{props.t('forgot_password')}</h2>

            <div style={{ marginLeft: '20px' }}>
                <input type="email" name="email_id" value={formData?.email_id ? formData?.email_id : ''} onChange={onChangeForm} placeholder={props.t('enter_your_email')} />
                {formError?.email_id ? (
                    <>
                        <br />
                        <span style={{ color: 'red', marginLeft: '5px' }}>{formError?.email_id}</span>
                    </>
                ) : null}
                <br />

                <button style={{ marginTop: '10px' }} onClick={verifyOnEmailSubmit}>{props.t('process')}</button>
            </div>
        </>
    )
}


ForgotPassword.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default (withTranslation()(ForgotPassword)); 