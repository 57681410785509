import React, { useEffect, useState } from "react";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
} from "../../helpers/global_helper";
import { NavLink, useNavigate } from "react-router-dom";
import DepositQueue from "./DepositQueue";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getPlayerProfileDetail } from "../../store/player-profile/actions";

const Deposit = (props) => {
  const navigate = useNavigate();
  const initData = {
    bank_id: "",
    amount: "",
    transaction_id: "",
    file_name: "",
    base64_file: "",
    promotion_id: "",
    rate_cal_amount: "",
    entered_remark: '',
    test: ""
  };

  const [formData, setFormData] = useState(initData);
  const [bankData, setBankData] = useState([]);
  const [formError, setFormError] = useState({});
  const [viewData, setViewData] = useState({});
  const [bankList, setBankList] = useState([]);
  const [
    depositWithdrawNotificationClosed,
    setDepositWithdrawNotificationClosed,
  ] = useState(false);
  const [depositWithdrawMessage, setDepositWithdrawMessage] = useState([]);
  const [dwMessageId, setDwMessageId] = useState(null);
  const [altSelect, setAltSelect] = useState("");
  const [bankType, setBankType] = useState(null);
  const [rate, setRate] = useState(0);
  const [checkedIndex, setCheckedIndex] = useState(null);

  const dispatch = useDispatch();
  const { userProfile } = useSelector((state) => ({
    userProfile: state.playerProfileR.data,
  }));

  useEffect(() => {
    dispatch(getPlayerProfileDetail());
  }, []);

  const getBankDetail = (bankId) => {
    makeAjax(
      url.DPWD_API.getBankDetail,
      { bank_id: bankId },
      url.DPWD_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
        }
        showMessage(response);
        return;
      }
      setBankData(response.data);
    });
  };

  const checkPendingTransaction = () => {
    makeAjax(url.DPWD_API.getDepostListTen, {}, url.DPWD_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response.messages);
          return;
        }
        setViewData({});
        response.data.data.map((data) => {
          if (
            data.status != "Approved" &&
            data?.transaction_type == "deposit"
          ) {
            setViewData(data);
            return;
          }
        });
      }
    );
  };

  const depositAmount = () => {

    if (formData.rate_cal_amount > 0) {
      formData.amount = formData.rate_cal_amount;
    }

    let payload = {
      ...formData

    }

    if (!payload['bank_id']) {
      payload['bank_id'] = userProfile?.player_bank_detail.bank_detail._id
    }

    makeAjax(url.DPWD_API.depositAmount, payload, url.DPWD_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code == 'inValidBank') {
            let error = formError;
            error['invalid_error'] = response.messages;
            setFormError(error);
          } else {
            setFormError(response.messages);
          }
          return;
        }
        showMessage(response.messages, "success");
        initData["bank_id"] = formData["bank_id"];
        setFormData(initData);
        checkPendingTransaction();
      }
    );
  };
  const inputHandler = (e) => {
    let val = e.target.value;
    let name = e.target.name;
    let newAmount = 0;
    let ratePer = parseFloat(rate);
    let temp = formData;
    formError[name] = "";

    if (name == "amount") {
      if (val != "") val = parseInt(val);
      if (isNaN(val)) {
        val = "";
      }
    }
    if (name == 'amount' && bankType == 'phone_credit' && ratePer > 0) {
      let commissionAmount = parseFloat(val) * parseFloat(rate) / 100;
      newAmount = (parseFloat(val) - commissionAmount).toFixed(2);
      formData['amount'] = val;
      formData['rate_cal_amount'] = isNaN(newAmount) ? '' : newAmount;
      formData['amount'] = isNaN(newAmount) ? '' : newAmount;
      formData['commission_amount'] = isNaN(commissionAmount) ? '' : commissionAmount;
      formData['bank_type'] = bankType;
    } else {
      if (name == 'entered_remark')
        temp['entered_remark'] = e.target.value;
    }

    setFormError({ ...formError });
    setFormData({
      ...formData,
      [name]: val,
    });
  };

  const uploadImage = (e) => {
    if (!e.target.files.length) {
      formData["base64_file"] = "";
      return;
    }
    let file = e.target.files[0];
    let file_name = e.target.files[0].name;
    let reader = new FileReader();
    reader.onload = (e) => {
      formData["base64_file"] = e.target.result;
      formData["file_name"] = file_name;
      setFormData({ ...formData });
    };
    reader.readAsDataURL(file);
  };
  const BankChange = (id, type, event, index) => {
    setFormData({ ...formData });
    setFormError({});
    let bankType =
      event.target.getAttribute("data-id");
    let ratePercentage =
      event.target.getAttribute(
        "data-rate"
      );
    setCheckedIndex(index);
    setBankType(bankType);
    if (ratePercentage != null && ratePercentage != "") {
      setRate(parseFloat(ratePercentage));
      if (formData.amount) {
        let commissionAmount = parseFloat(formData.amount) * parseFloat(ratePercentage) / 100;
        formData.rate_cal_amount = (parseFloat(formData.amount) - commissionAmount).toFixed(2);
      }

    }

    setAltSelect(type);
    if (type != 'alternative') {
      getBankDetail(id);
    }

    formData['bank_id'] = id;
    setFormData({ ...formData });
  };

  const playerBankDD = () => {
    makeAjax(url.USER_API.playerBankDD, {}, url.USER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response.messages);
          return;
        }
        setBankList(response.data);
        if (response.data.length > 0) {
          getBankDetail(response.data[0]._id);
        }
        if (response.data[0].tenant_bank_rate) {
          setRate(parseFloat(response.data[0].tenant_bank_rate.rate_percentage));
        }
      }
    );
  };

  const messageRead = () => {
    if (messageRead != null) {
      let payload = {
        _id: dwMessageId,
      };
      makeAjax(
        url.DPWD_API.depositWithdrawReadMessage,
        payload,
        url.DPWD_MS_EXT
      ).then((response) => {
        if (response.status != respStatus["SUCCESS"]) {
          showMessage(response);
          return;
        }
      });
    }
  };

  const depositWithdrawNotificationMessage = () => {
    makeAjax(
      url.DPWD_API.depositWithdrawNotificationMessage,
      [],
      url.DPWD_MS_EXT
    ).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        showMessage(response);
        return;
      }
      setDepositWithdrawMessage(response.data);
      if (response.data.length > 0 && response.data[0].transaction_type == 'deposit') {
        setDwMessageId(response.data[0]._id);
        setDepositWithdrawNotificationClosed(true);
      }
    });
  };

  useEffect(() => {
    checkPendingTransaction();
    playerBankDD();
    depositWithdrawNotificationMessage();
    setCheckedIndex(userProfile?.player_bank_detail?.bank_detail?._id);
  }, [userProfile?.player_bank_detail?.bank_detail?._id]);

  return (
    <div>
      <h5>
        <NavLink to="/">{props.t("back_to_main_menu")}</NavLink>
      </h5>
      <h5>
        <NavLink to="/money">{props.t("back")}</NavLink>
      </h5>
      <h3>{props.t("deposit")}</h3>
      {depositWithdrawNotificationClosed ? (
       
        <>
          {depositWithdrawMessage != undefined &&
            depositWithdrawMessage[0]?.transaction_type == "deposit" &&
            depositWithdrawMessage[0]?.status == "Approved" && (
              <>
                <p>
                  {" "}
                  {props.t("we_have_successfully_processed_your")} IDR{" "}
                  {depositWithdrawMessage[0]?.amount} {props.t("deposit")}.
                </p>
                <p>
                  {" "}
                  {props.t("please_check_your_memo_for_more_information")}.
                </p>
                <button
                  onClick={() => {
                    messageRead(depositWithdrawMessage[0]?._id);
                    navigate("/inbox");
                  }}
                >
                  {props.t("ok")}
                </button>
              </>
            )}
          {depositWithdrawMessage != undefined &&
            depositWithdrawMessage[0]?.transaction_type == "deposit" &&
            depositWithdrawMessage[0]?.status == "Rejected" && (
              <>
                <p> {props.t("your_deposit_was_rejected")}.</p>
                <p>
                  {" "}
                  {props.t("please_check_your_memo_for_more_information")}.
                </p>
                <button
                  onClick={() => {
                    messageRead(depositWithdrawMessage[0]?._id);
                    navigate("/inbox");
                  }}
                >
                  {props.t("ok")}
                </button>
              </>
            )}

          {depositWithdrawMessage != undefined &&
            depositWithdrawMessage[0]?.transaction_type == "manual_deposit" &&
            depositWithdrawMessage[0]?.status == "Approved" && (
              <>
                <p>
                  {" "}
                  {props.t("we_have_success_fully_processed_your")} IDR{" "}
                  {depositWithdrawMessage[0]?.amount} {props.t("deposit")}.
                </p>
                <p>
                  {" "}
                  {props.t("please_check_your_memo_for_more_information")}.
                </p>
                <button
                  onClick={() => {
                    messageRead(depositWithdrawMessage[0]?._id);
                    navigate("/inbox");
                  }}
                >
                  {props.t("ok")}
                </button>
              </>
            )}
        </>
      ) 
    :
      
        <>
          {Object.values(viewData).length > 0   ? (
            <DepositQueue viewData={viewData} userProfile={userProfile} />
          ) : (
            <div style={{ marginLeft: "15px" }}>
              <p>
                {props.t("welcome")} {userProfile?.username}
              </p>
              <div style={{ marginTop: "5px" }}>
                <span style={{ marginRight: "200px " }}>
                  {props.t("bank")}
                </span>
                :
                {userProfile?.player_bank_detail &&
                  userProfile?.player_bank_detail.bank_detail ? (
                  <span style={{ marginLeft: "7px" }}>
                    {userProfile?.player_bank_detail.bank_detail.bank_name}
                  </span>
                ) : null}
              </div>
              <div style={{ marginTop: "5px" }}>
                <span style={{ marginRight: "129px " }}>
                  {props.t("account_name")}
                </span>
                :
                {userProfile?.account_name ? (
                  <span style={{ marginLeft: "7px" }}>
                    {userProfile?.account_name}
                  </span>
                ) : null}
              </div>
              <div style={{ marginTop: "5px" }}>
                <span style={{ marginRight: "74px " }}>
                  {props.t("bank_account_number")}
                </span>
                :
                {userProfile?.player_bank_detail &&
                  userProfile?.player_bank_detail.account_number ? (
                  <span style={{ marginLeft: "7px" }}>
                    {userProfile?.player_bank_detail.account_number}
                  </span>
                ) : null}
              </div>
              <div style={{ marginTop: "5px" }}>
                <span style={{ marginRight: "140px " }}>
                  {props.t("total_deposit")}
                </span>
                :
                <span style={{ marginLeft: "7px" }}>
                  <input
                    type="text"
                    placeholder={props.t("enter_amount")}
                    name="amount"
                    value={formData.amount}
                    onChange={inputHandler}
                  />
                </span>
              </div>
              {formError.amount ? (
                <span style={{ color: "red", marginLeft: "245px" }}>
                  {formError.amount}
                </span>
              ) : null}
              <div style={{ marginTop: "5px" }}>
                <span style={{ marginRight: "140px " }}>
                  {props.t("enter_remark")}
                </span>
                :
                <span style={{ marginLeft: "7px" }}>
                  <input
                    type="text"
                    placeholder={props.t("remark")}
                    name="entered_remark"
                    value={formData.entered_remark}
                    onChange={inputHandler}
                  />
                </span>
              </div>
              <div style={{ marginTop: "5px" }}>
                <span style={{ marginRight: "88px " }}>
                  {props.t("evidence_of_transfer")}
                </span>
                :
                <span style={{ marginLeft: "7px" }}>
                  <input
                    type="file"
                    name="file_name"
                    accept="image/*"
                    onChange={(e) => {
                      uploadImage(e);
                    }}
                    required
                  />
                </span>
              </div>
              {formError.file_name ? (
                <span style={{ color: "red", marginLeft: "245px" }}>
                  {formError.file_name}
                </span>
              ) : null}
              {
                bankType == 'phone_credit' &&
                <>
                  <div style={{ marginTop: "5px" }}>
                    <span style={{ marginRight: "200px " }}>
                      {props.t("rate")}
                    </span>
                    :
                    <span style={{ marginLeft: "7px" }}>
                      <input
                        disabled
                        type="text"
                        placeholder={rate + " %"}
                        name="amount"
                      />
                    </span>
                  </div>

                  <div style={{ marginTop: "5px" }}>
                    <span style={{ marginRight: "100px " }}>
                      {props.t("deposit_accepted")}:
                    </span>
                    :
                    <span style={{ marginLeft: "7px" }}>
                      <input
                        disabled
                        name="rate_cal_amount"
                        type="text"
                        placeholder={props.t("amount")}
                        value={formData.rate_cal_amount}
                      />
                    </span>
                  </div>
                </>
              }

              <div style={{ marginTop: "5px" }}>
                <span style={{ marginRight: "114px " }}>
                  {props.t("account_deposit")}
                </span>
                :
                {bankList.map((data, index) => {
                  return (
                    <React.Fragment key={index}>
                      <span
                        style={
                          index == 0
                            ? { marginLeft: "17px" }
                            : { marginLeft: "254px" }
                        }
                      >
                        <input
                          type="radio"
                          onChange={() => { }}
                          name="test"
                          onClick={(event) => {
                            BankChange(data?._id, "bank", event, data?._id);

                          }}
                          key={data._id} data-rate={data?.tenant_bank_rate?.rate_percentage} data-id={data?.bank_type} value={data._id}
                          checked={
                            userProfile?.player_bank_detail.bank_detail._id && !formData["bank_id"] ? (userProfile?.player_bank_detail.bank_detail._id == data?._id) : (data?._id == formData["bank_id"] &&
                              altSelect != "alternative")
                          }
                        />

                      </span>
                      <span style={{ marginLeft: "7px" }}>
                        {data?.bank_name} - {data?.tenant_bank?.status}
                      </span>
                      {formError.invalid_error && data._id == checkedIndex ? (
                        <span style={{ color: "red", marginLeft: "20px" }}>
                          {formError.invalid_error}
                        </span>
                      ) : null}
                      <br />
                    </React.Fragment>
                  );
                })}
              </div>
              {bankData?.alternateBank && (
                <div style={{ marginTop: "5px" }}>
                  <span style={{ marginRight: "134px " }}>
                    {props.t("alternate_bank")}
                  </span>
                  :
                  <span>
                    <span style={{ marginLeft: "14px" }}>
                      <input
                        type="radio"
                        name="test"
                        onChange={() => { }}
                        onClick={(event) => {
                          BankChange(bankData?.alternateBank?.bank_id, "alternative", event);
                        }}
                        checked={
                          bankData?.alternateBank?.bank_id ==
                          formData["bank_id"] && altSelect != "bank"
                        }
                      />
                    </span>

                    <span style={{ marginLeft: "7px" }}>
                      {bankData?.alternateBank?.bank_name},{" "}
                      {bankData?.alternateBank?.account_number},{" "}
                      {bankData?.alternateBank?.account_name} -
                      {bankData?.alternateBank?.status}
                    </span>
                  </span>
                </div>
              )}
              <div style={{ marginTop: "25px", marginLeft: "245px" }}>
                <span>
                  <button onClick={depositAmount}>
                    {props.t("process")}
                  </button>
                </span>
              </div>
            </div>
          )}
        </>

      } 
     
    </div>
  );
};

Deposit.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(Deposit);
