import {
  FETCH_PLAYER_PROFILE,
  FETCH_PLAYER_PROFILE_SUCCESS,
  RESET_PLAYER_PROFILE
} from "./actionTypes"


export const getPlayerProfileDetail = () => {
  return {
    type: FETCH_PLAYER_PROFILE,
    payload: {},
  }
} 

export const resetPlayerProfile = () => {
  return {
    type: RESET_PLAYER_PROFILE,
    payload: {},
  }
}

export const getPlayerProfileDetailSuccess = (response) => {
  return {
    type: FETCH_PLAYER_PROFILE_SUCCESS,
    payload: response,
  }
}