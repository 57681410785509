import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPlayerProfileDetail } from "../../store/player-profile/actions";
import { getLogout } from '../../store/logout/action';
import { FiRefreshCw } from "react-icons/fi";
import { RiInformationLine } from "react-icons/ri";

import LaunguageDropdown from "./LaunguageDropdown";

const Dashboard = props => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const { userProfile } = useSelector(state => ({
    userProfile: state.playerProfileR.data,
  }));


  const [gameCategory, setgameCategory] = useState([]);
  const [balance, setBalance] = useState('');
  const [isClicked, setIsClicked] = useState('');
  const [promotionCategory, setPromotionCategory] = useState([]);
  const logout = () => {
    dispatch(getLogout());
    navigate('/');
  }


  const listWapUserSubMenu = () => {
    makeAjax(
      url.USER_API.listWapUserSubMenu, [], url.USER_MS_EXT).then(response => {
        if (response) {
          if (response.status != respStatus['SUCCESS']) {
            showMessage(response);
            return;
          }
          setgameCategory(response.data);
        }
      });
  };

  const getBalance = () => {
    makeAjax(
      url.GP_API.getBalance, [], url.GP_MS_EXT).then(response => {
        if (response) {
          if (response.status != respStatus['SUCCESS']) {
            showMessage(response);
            return;
          }
          setBalance(response.data);
        }
      });
  };
  const transferIn = () => {
    makeAjax(
      url.GP_API.transferIn, { "withdraw": true }, url.GP_MS_EXT).then(response => {
        if (response) {
          if (response.status != respStatus['SUCCESS']) {
            showMessage(response);
            return;
          }
        }
      });
  };

  const handleIconClick = () => {
    makeAjax(url.ADMIN_API.promotionCategoryNameDD, [], url.ADMIN_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          return;
        }
        setPromotionCategory(response.data);
      }
    );
    setIsClicked(!isClicked);
  };

  const handleRefresh = () => {
    getBalance();
    transferIn();
  }

  useEffect(() => {
    dispatch(getPlayerProfileDetail());
    listWapUserSubMenu();
    getBalance();
  }, []);

  return (
    <>
      <h4 className="title">{props.t('welcome')}, {userProfile?.username}</h4>
      <div>
        <span>{props.t('available_balance')}: {parseFloat(Number(balance?.main_balance).toFixed(3))}</span>
        <span>
          <button onClick={handleRefresh}><FiRefreshCw />{props.t('refresh')}</button>
        </span>
        <br />
        <span>{props.t('promo_chips')}: {parseFloat(Number(balance?.promo_balance).toFixed(3))}&nbsp;&nbsp;

          <button onClick={() => { handleIconClick(); }} disabled={balance?.promo_balance <= 0} ><RiInformationLine /> </button>
          {isClicked && promotionCategory.length > 0 && balance?.promo_balance > 0 && (
            <div className="click-content">
              <p>
                {

                  props.t(
                    "following_categories_are_applicable_for_promos"
                  )}
              </p>
              <ul>
                {promotionCategory.length > 0 && promotionCategory.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}
        </span> &nbsp;&nbsp;

      </div>
      <LaunguageDropdown />
      <ul>
        <li>
          <NavLink to='/profile'>{props.t('profile')}</NavLink>
        </li>
        {
          Object.values(gameCategory).map((gamingOption, index) => {
            const providerDetails = Object.values(gamingOption)[0];
            const encodedData = encodeURIComponent(JSON.stringify(gameCategory));
            return (
              <li key={index}>
                <Link to={`/provider-list?data=${encodedData}&menu_code=${providerDetails?.menu_code}&provider_code=${providerDetails?.providerDetails?.tenantProviderInfo?.provider_name}&provider_name=${providerDetails?.providerDetails?.providerInfo?.name}`}>{providerDetails.name}</Link>
              </li>
            )
          })

        }
        <li>
          <NavLink to='/money'>{props.t('cashier')}</NavLink>
        </li>
        <li>
          <NavLink to='/messages'>{props.t('memo')}</NavLink>
        </li>
        <li>
          <NavLink to='/change-password-after-login'>{props.t('change_password')}</NavLink>
        </li>
        <li>
          <NavLink className='cancel' type='button' onClick={logout} >{props.t('log_out')}</NavLink>
        </li>
      </ul>
    </>
  );
}

Dashboard.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(Dashboard);
