import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper";
import i18n from '../../i18n';
import { useDispatch, useSelector } from "react-redux";
import { getPlayerProfileDetail } from "../../store/player-profile/actions";

const LaunguageDropdown = (props) => {

    const dispatch = useDispatch();
    const { userProfile } = useSelector(state => ({
        userProfile: state.playerProfileR.data,
    }));


    let isLogin = localStorage.getItem('user_wap_app_token');

    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [langList, setLangList] = useState([]);
    const launguageData = () => {
        makeAjax(url.MASTER_API.languageDD, {}, url.MASTER_MS_EXT).then(response => {
            if (response.status != respStatus['SUCCESS']) {
                showMessage(response);
                return;
            }
            let currentLaung = localStorage.getItem("I18N_LANGUAGE");
            setLangList(response.data);
            response?.data.map((data) => {
                if (data?.code == currentLaung) {
                    setSelectedLanguage(data?.code);
                    i18n.changeLanguage(data?.code);
                    localStorage.setItem("I18N_LANGUAGE", data?.code);
                }
            })
        });
    }
    const changeLanguageAction = (event) => {
        let lang = event.target.value;
        localStorage.removeItem('I18N_LANGUAGE');
        localStorage.setItem("I18N_LANGUAGE", lang);
        setSelectedLanguage(lang);
        makeAjax(url.USER_API.updateMyLanguage, { 'lang': lang }).then(response => {
            if (response.status != respStatus['SUCCESS']) {
                showMessage(response);
                return;
            }
            i18n.changeLanguage(lang);
        });
    }

    useEffect(() => {
        if (userProfile?.language_code) {
            i18n.changeLanguage(userProfile?.language_code);
            localStorage.setItem("I18N_LANGUAGE", userProfile?.language_code);
            setSelectedLanguage(userProfile?.language_code);
        }

        if (isLogin)
            dispatch(getPlayerProfileDetail());

    }, [isLogin])
    useEffect(() => {
        launguageData();
    }, []);

    return (
        <table>
            <tbody>
                <tr>
                    <td>{props.t('language')}</td>
                    <td>:</td>
                    <td>
                        <select
                            type="select"
                            value={selectedLanguage}
                            onChange={changeLanguageAction}
                            name="language"
                            defaultValue={selectedLanguage}
                            placeholder={props.t('language')}
                        // defaultValue={selectedLanguage}
                        >
                            {langList.map((data, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <option value={data?.code}>{data?.name}</option>
                                    </React.Fragment>
                                )
                            })}
                        </select>
                    </td>
                </tr>
            </tbody>
        </table>
    )
}

LaunguageDropdown.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(LaunguageDropdown);
