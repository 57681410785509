import React from 'react'

function CommingSoon() {
    return (
        <div>
            <h1>Page Comming Soon</h1>
        </div>
    )
}

export default CommingSoon
