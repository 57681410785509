import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper";
import { NavLink } from "react-router-dom";
import MailDeatails from "./MailDeatails";

const Inbox = props => {
    const [msgData, setMsgData] = useState('');
    const [playerInboxMessage, setPlayerInboxMessage] = useState([]);

    const msgStatus = (event) => {
        makeAjax(url.MEMO_API.playerUpdateMessageStatus, { _id: event._id }, url.MEMO_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                        return;
                    }
                    showMessage(response);
                    return;
                }
            })
    }
    const getPlayerInboxMessageList = (event) => {
        makeAjax(url.MEMO_API.listPlayerInboxMessage, [], url.MEMO_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    showMessage(response);
                    return;
                }
                setPlayerInboxMessage(response.data);
            })
    }

    const deleteConfirmMessage = (deleteId) => {
        makeAjax(url.MEMO_API.deletePlayerMessage, { _id: [deleteId] }, url.MEMO_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    showMessage(response);
                    return;
                }
                showMessage(response);
                getPlayerInboxMessageList();
            })
    }

    useEffect(() => {
        getPlayerInboxMessageList();
    }, []);


    return (
        <>
            <div style={{ marginLeft: '10px' }}>

                <h5><NavLink to='/'>{props.t('back_to_main_menu')}</NavLink></h5>
                {msgData != '' ? (
                    <>
                        <h5><NavLink onClick={() => setMsgData('')}>{props.t('back')}</NavLink></h5>
                        <MailDeatails msgData={msgData} type='inbox' />
                    </>) : (<>
                        <table>
                            <tbody>
                                <tr>
                                    <th>{props.t('no')}</th>
                                    <th>{props.t('operator')}</th>
                                    <th>{props.t('subject')}</th>
                                    <th>{props.t('date')}/{props.t('time')}</th>
                                </tr>
                                {typeof playerInboxMessage.data != 'undefined' && playerInboxMessage.data.length > 0 && playerInboxMessage.data.map((data, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{data.domain_name}</td>
                                            {
                                                !data.status && <td
                                                    onClick={() => { setMsgData(data); msgStatus(data) }}
                                                    style={{ whiteSpace: 'nowrap', width: '180px', overflow: 'hidden', display: 'block', textAlign: 'center', margin: '10px', color: 'blue', cursor: 'pointer' }}>{data.subject}</td>
                                            }

                                            {
                                                data.status && <td
                                                    onClick={() => { setMsgData(data); msgStatus(data) }}
                                                    style={{ whiteSpace: 'nowrap', width: '180px', overflow: 'hidden', display: 'block', textAlign: 'center', margin: '10px', color: 'black', cursor: 'pointer' }}>{data.subject}</td>
                                            }
                                            <td>{data.created_at}</td>
                                            <td><button onClick={() => deleteConfirmMessage(data._id)}>Delete</button></td>
                                            {/* <td style={{ whiteSpace: 'nowrap', width: '180px', overflow: 'hidden', display: 'block', textAlign: 'center', margin: '10px', color: 'blue' }}>{data.subject}</td> */}
                                        </tr >
                                    )
                                }
                                )}
                            </tbody>
                        </table>
                    </>)
                }
            </div >
        </>
    )
}

Inbox.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(Inbox);
