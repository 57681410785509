import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

const Cashier = props => {

    return (
        <>
            <div style={{ marginTop: '20px', marginLeft: '15px' }}>
                <h5><NavLink to='/'>{props.t('back_to_main_menu')}</NavLink></h5>
                <ul>
                    <li>
                        <NavLink to='/deposit'>{props.t('deposit')}</NavLink>
                    </li>
                    <li>
                        <NavLink to='/withdraw'>{props.t('withdraw')}</NavLink>
                    </li>
                </ul >
                <NavLink to="https://user-test.api-hkbgaming88.com/">{props.t('mobile_version')}</NavLink>
            </div>
        </>
    );
}

Cashier.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(Cashier);
