import { useEffect, useState } from "react"
import { FiRefreshCw } from "react-icons/fi";
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const TransactionQueue = (props) => {
    const [pendingCount, setPendingCount] = useState(null);
    const [timeTaken, setTimeTaken] = useState(null);

    const checkWithdrawPendingRequest = () => {
        makeAjax(
            url.DPWD_API.checkWithdrawPendingRequest, [], url.DPWD_MS_EXT).then(response => {
                if (response && response.status != respStatus['SUCCESS']) {
                    showMessage(response);
                    return;
                }
                if (response.data?.count > 0) {
                    setTimeTaken(response.data?.count * 4);
                }
                setPendingCount(response.data?.count);
            });

    }

    useEffect(() => {
        checkWithdrawPendingRequest()
    }, []);
    return (
        <>
            <div style={{ paddingLeft: '15px' }}>
                <h4>{props.t('withdraw_inprocess')}</h4>
                <div>
                    <h3>{props.t('we_have_received_withdraw_request_for')}: <span style={{ color: '#f59e0b' }}> {props?.viewData?.amount} {props?.userProfile?.currency}</span></h3>
                    <table>
                        <thead>
                            <tr>
                                <td>{props.t('bank')} / {props.t('e_wallet')}</td>
                                <td> : </td>
                                <td>
                                    {props?.viewData?.bank_name}
                                </td>
                            </tr>
                            <tr>
                                <td>{props.t('account_name')} / {props.t('e_wallet')}</td>
                                <td> : </td>
                                <td>
                                    {props?.viewData?.user_account_name}
                                </td>
                            </tr>
                            <tr>
                                <td>{props.t('account_number')} / {props.t('handphone_number')}</td>
                                <td> : </td>
                                <td>
                                    {props?.viewData?.user_account_number}
                                </td>
                            </tr>
                        </thead>
                    </table>

                    <table style={{ marginTop: '50px' }}>
                        <thead>
                            <th>
                                {props.t('number')}
                            </th>
                            &nbsp;&nbsp;&nbsp;
                            <th>
                                {props.t('line')}
                            </th>
                            &nbsp;&nbsp;&nbsp;
                            
                                <th>
                                    {props.t('duration')}
                                </th>
                            


                        </thead>
                        <tbody style={{ textAlign: 'center' }}>
                            <tr>
                                <td>
                                    {pendingCount}
                                </td>&nbsp;&nbsp;&nbsp;
                                <td>
                                    {props?.viewData?.withdraw_group}
                                </td>&nbsp;&nbsp;&nbsp;
                                <td>
                                    {timeTaken &&
                                        timeTaken 
                                    }
                                </td>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <td>
                                    {/*  */}
                                    <button onClick={props?.checkPendingTransaction}>
                                        <FiRefreshCw /> {props.t('refresh')}
                                    </button>
                                </td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            </div>
        </>
    )
}

TransactionQueue.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(TransactionQueue);