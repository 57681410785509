import React, { useState, useEffect } from 'react'
import {
    Col,
    Row,
} from "reactstrap";
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper";
import { getLogout } from '../../store/logout/action';
import { useDispatch } from 'react-redux';

const CreatePin = props => {

    const navigate = useNavigate()

    const InitFormData = {
        pin: '',
        pin_confirmation: '',
        type: 'login-pin'
    }

    const [formData, setFormData] = useState(InitFormData)
    const [formError, setFormError] = useState({});
    const [getData, setData] = useState([]);

    const dispatch = useDispatch();
    const logout = () => {
        dispatch(getLogout());
        navigate('/');
    }

    const getPinStatus = () => {
        makeAjax(
            url.USER_API.listPlayerAlternativeSite, [], url.USER_MS_EXT).then(response => {
                if (response.data.length > 0) {
                    let config_status = response.data.get_tenant_config.value;
                    setData(response.data)
                    if (config_status === true) {
                        if (response?.data?.enabled_active_pin === true && response?.data?.active_pin === false) {
                            // if (response.data.active_pin === '' || response.data.active_pin === null && response.data.enabled_active_pin === true) {
                            navigate("/create-pin");
                            return;
                        }
                        else if (response?.data?.enabled_active_pin === true && response?.data?.is_pin_verified === false) {
                            navigate("/validation-pin");
                            return;
                        }
                        else if ((response?.data?.is_security_question_set === false || response?.data?.is_security_question_set === null)) {
                            navigate("/security-question");
                            return;
                        }
                        else {
                            navigate("/");
                            return;
                        }
                    } else {
                        navigate("/");
                        return;
                    }
                }
            });
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        makeAjax(
            url.USER_API.setUserNewPin,
            formData,
            url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error')
                        showMessage(response);
                    setFormError(response.messages);
                    return;
                }
                setFormError({});
                setFormData({ ...formData, ...{ pin: '', pin_confirmation: '' } });
                navigate("/security-question");
                if (getData?.is_security_question_set === false || getData?.is_security_question_set === null) {
                    // if (getData.is_security_question_set === false) {
                    navigate("/security-question");
                } else {
                    navigate("/validation-pin");
                }
                showMessage(response);
            });
    }

    const inputHandler = (event) => {
        let val = event.target.value;
        if (val != '')
            val = parseInt(val);
        if (isNaN(val)) {
            val = '';
        }
        setFormData({
            ...formData, [event.target.name]: val.toString()
        })
    }

    useEffect(() => {
        getPinStatus();
    }, []);
    return (
        <div style={{ marginLeft: '15px' }}>
            <div>
                <h3>{props.t('create_pin')}</h3>
            </div>
            <div>
                <h5>{props.t('enter_your_six_digits_pin')}</h5>
                <p>{props.t('this_system_is_to_increase_security_and_privacy_of_your_account')}</p>
            </div>
            <div>
                <form>
                    <div>
                        <input type="password" name="pin" placeholder={props.t('enter_your_new_pin')} value={formData.pin} onChange={inputHandler} maxLength={6}
                            pattern="[^\s]+" />
                        <br />
                        {formError.pin ? (
                            <span style={{ color: 'red' }}>{formError.pin}</span>
                        ) : null}

                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <input type="password" placeholder={props.t('confirm_your_new_pin')} name="pin_confirmation" value={formData.pin_confirmation} onChange={inputHandler} maxLength={6}
                            pattern="[^\s]+" />
                        <br />
                        {formError.pin_confirmation ? (
                            <span style={{ color: 'red' }}>{formError.pin_confirmation}</span>
                        ) : null}
                    </div>
                    <button type='button' onClick={(e) => { handleSubmit(e) }} style={{ marginTop: '5px' }}>{props.t('process')}</button> &nbsp;&nbsp;&nbsp;&nbsp;
                    <button type='button' onClick={logout} style={{ marginTop: '5px' }}>{props.t('out')}</button>
                </form>
                <span>{props.t('this_pin_will_be_used_every_time_you_enter_the_game')}</span>
            </div>
        </div>
    )
}
CreatePin.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(CreatePin);

