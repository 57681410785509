import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper";
import { Link, useNavigate } from "react-router-dom";
import { BiShowAlt } from 'react-icons/bi';
import { AiOutlineEyeInvisible } from 'react-icons/ai'

const Register = props => {
  const navigate = useNavigate();

  const InitForm = {
    username: '',
    password: '',
    password_confirmation: '',
    security_question_id: '',
    security_answer: '',
    contact: '',
    email: '',
    bank_id: '',
    account_name: '',
    account_number: '',
    referrals: '',
    key: '',
    validation_length: '',
  };
  const [formData, setFormData] = useState(InitForm);
  const [formError, setFormError] = useState({});
  const [bankListData, setBankListData] = useState([]);
  const [inputType, setInputType] = useState("password");
  const [inputConfirmType, setInputConfirmType] = useState("password");
  const [bankMinLength, setBankMinLength] = useState(0);
  const [bankMaxLength, setBankMaxLength] = useState(0);

  const parseLength = (inputStr) => {
    let lengths = inputStr.split(',');
    let min = parseInt(lengths[0]);
    let max = lengths.length > 1 ? parseInt(lengths[1]) : min;
    return { min, max };
  }

  const getBankList = () => {
    makeAjax(
      url.USER_API.bankDD, [], url.USER_MS_EXT).then(response => {
        if (response.status != respStatus['SUCCESS']) {
          showMessage(response);
          return;
        }
        setBankListData(response.data);
      });
  }

  const onChangeForm = (event) => {

    let name = event.target.name;
    let val = event.target.value;
    if (name == 'account_number') {
     if (formData['bank_id'] != '' && val.length) {
        const validInput = val.replace(/[^0-9+-]/g, '');
        setFormData({ ...formData, [name]: validInput });
        // val = val.substring(0, formData['validation_length']);
      } else {
        val = String(parseInt(val));
      }
      if (isNaN(val))
        val = '';
    }

    if (name == 'bank_id') {
      formData.account_number = '';
      setFormData({ ...formData });
      bankListData.map((data) => {
        if (data._id == val) {
          // parseLength
          const { min, max } = parseLength(data['validation_length']);
          setBankMinLength(min);
          setBankMaxLength(max);
          // formData['validation_length'] = ;
        }
      })
    }

    setFormError({ ...formError, [name]: '' });

    setFormData({ ...formData, [name]: val });

  }

  const registerUser = (event) => {
    makeAjax(
      url.USER_API.userRegistration,
      formData,
      url.USER_MS_EXT).then(response => {
        if (response.status != respStatus['SUCCESS']) {
          if (response.code != 'validation_error' && response.code != 'validation.credential_error') {
            showMessage(response);
            return;
          }
          formData['key'] = '';
          setFormData({ ...formData });
          setFormError(response.messages);
          return;
        }
        setFormError({});
        showMessage(response);
        LogInAfterRegister();
      });
  }

  const LogInAfterRegister = () => {
    let payload = {
      username: formData.username,
      password: formData.password,
      key: "",
    }
    makeAjax(url.USER_API.login, payload, '')
      .then(response => {
        if (response.status !== respStatus['SUCCESS']) {
          if (response.code !== 'validation_error') {
            showMessage(response);
          }
          return;
        }
        localStorage.setItem('user_wap_app_token', response.data.token);
        props.setToken();
        navigate('/create-pin');
        localStorage.setItem('device_type', 'mobile');

      })
  }

  const showPassword = (event) => {
    if (inputType == 'password')
      setInputType("text");
    if (inputType == 'text')
      setInputType("password");
  }

  const showConfirmPassword = (event) => {
    if (inputConfirmType == 'password')
      setInputConfirmType("text");
    if (inputConfirmType == 'text')
      setInputConfirmType("password");
  }

  useEffect(() => {
    getBankList();
  }, []);

  return (
    <>
      <h2>{props.t('registration')}</h2>
      <form autoComplete="off">
        <div >
          <label style={{ marginRight: '113px' }}>{props.t('username')} </label>
          :
          <input
            type="text"
            value={formData.username}
            onChange={onChangeForm}
            name="username"
            placeholder={props.t('username')}
            style={{ marginLeft: '7px', autoComplete: 'off', width: '300px' }}
          />
        </div>
        {formError.username ? (
          <span style={{ color: 'red', marginLeft: '195px' }}>{formError.username}</span>
        ) : null}
        <div >
          <div >
            <label style={{ marginRight: '113px' }}>{props.t('password')} </label>
            :
            <input
              type={inputType}
              value={formData.password}
              onChange={onChangeForm}
              name="password"
              placeholder={props.t('password')}
              style={{ marginLeft: '7px', marginTop: '10px', width: '300px' }}
              autoComplete='new-password'
            />
            <span style={{ marginTop: "-19px", display: 'flex', marginLeft: '480px' }}>
              {inputType == 'password' && <AiOutlineEyeInvisible onClick={showPassword} />}
              {inputType == 'text' && <BiShowAlt onClick={showPassword} />}
            </span>
          </div>
          {formError.password ? (
            <span style={{ color: 'red', marginLeft: '195px' }}>{formError.password}</span>
          ) : null}
        </div>
        <div >
          <div >
            <label style={{ marginRight: '16px' }}>{props.t('password_confirmation')} </label>
            :
            <input
              type={inputConfirmType}
              value={formData.password_confirmation}
              onChange={onChangeForm}
              name="password_confirmation"
              placeholder={props.t('password_confirmation')}
              style={{ marginLeft: '7px', marginTop: '10px', width: '300px' }}
            />
            <span style={{ marginTop: "-19px", display: 'flex', marginLeft: '480px' }}>
              {inputConfirmType == 'password' && <AiOutlineEyeInvisible onClick={showConfirmPassword} />}
              {inputConfirmType == 'text' && <BiShowAlt onClick={showConfirmPassword} />}
            </span>
          </div>
          {formError.password_confirmation ? (
            <span style={{ color: 'red', marginLeft: '195px' }}>{formError.password_confirmation}</span>
          ) : null}
        </div>
        <div >
          <label style={{ marginRight: '128px' }}>{props.t('contact')} </label>
          :
          <input type="text"
            value={formData.contact}
            onChange={onChangeForm}
            name="contact"
            placeholder={props.t('contact')}
            style={{ marginLeft: '7px', marginTop: '10px', width: '300px' }}
          />
        </div>
        {formError.contact ? (
          <span style={{ color: 'red', marginLeft: '195px' }}>{formError.contact}</span>
        ) : null}
        <div >
          <label style={{ marginRight: '116px' }}>{props.t('e_mail_id')} </label>
          :
          <input type="text"
            value={formData.email}
            onChange={onChangeForm}
            name="email"
            placeholder={props.t('e_mail_id')}
            style={{ marginLeft: '7px', marginTop: '10px', width: '300px' }}
          />
        </div>
        {formError.email ? (
          <span style={{ color: 'red', marginLeft: '195px' }}>{formError.email}</span>
        ) : null}
        <div >
          <label style={{ marginRight: '35px' }}>{props.t('select_bank')}/{props.t('e_wallet')} </label>
          :
          <select
            type="select"
            value={formData.bank_id}
            onChange={onChangeForm}
            name="bank_id"
            placeholder={props.t('choose_bank')}
            style={{ marginLeft: '7px', marginTop: '10px', width: '305px' }}
          >
            <option value='select'>---{props.t('select')}---</option>
            {bankListData && bankListData.map((data, index) => {
              return (
                <option value={(data._id)} key={index}>{data.bank_name}</option>
              )
            })}
          </select>
        </div>
        {formError.bank_id ? (
          <span style={{ color: 'red', marginLeft: '195px' }}>{formError.bank_id}</span>
        ) : null}
        <div >
          <label style={{ marginRight: '79px' }}>{props.t('account_name')} </label>
          :
          <input type="text"
            value={formData.account_name}
            onChange={onChangeForm}
            name="account_name"
            placeholder={props.t('account_name')}
            style={{ marginLeft: '7px', marginTop: '10px', width: '300px' }}
          />
        </div>
        {formError.account_name ? (
          <span style={{ color: 'red', marginLeft: '195px' }}>{formError.account_name}</span>
        ) : null}
        <div >
          <label style={{ marginRight: '63px' }}>{props.t('account_number')} </label>
          :
          <input type="text"
            value={formData.account_number}
            onChange={onChangeForm}
            name="account_number"
            placeholder={props.t('account_number')}
            maxLength={bankMaxLength}
            minLength={bankMinLength}
            style={{ marginLeft: '7px', marginTop: '10px', width: '300px' }}
          />
        </div>
        {formError.account_number ? (
          <span style={{ color: 'red', marginLeft: '195px' }}>{formError.account_number}</span>
        ) : null}
        <div>
          <label style={{ marginRight: '123px' }}>{props.t('referrals')} </label>
          :
          <input type="text"
            value={formData.referrals}
            onChange={onChangeForm}
            name="referrals"
            placeholder={props.t('referrals')}
            style={{ marginLeft: '7px', marginTop: '10px', width: '300px' }}
          />
        </div>
        {formError.referrals ? (
          <span style={{ color: 'red', marginLeft: '195px' }}>{formError.referrals}</span>
        ) : null}

        <br />
        <input type={"button"} value={props.t('submit')} onClick={() => { registerUser(); }} style={{ marginLeft: '198px', marginTop: '20px' }} />
      </form>
      <br />
      <Link to="/">{props.t('back_to_login')}</Link>

    </>
  );
}


Register.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(Register);
