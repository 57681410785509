import PropTypes from "prop-types";
import { Link, useNavigate, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { withTranslation } from "react-i18next";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
} from "../../helpers/global_helper";
import { BiShowAlt } from "react-icons/bi";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import LaunguageDropdown from "../commonPages/LaunguageDropdown";
// import images
const Login = (props) => {
  // Init form data
  const InitFormData = {
    username: "",
    password: "",
  };

  // set & get state
  const [formData, setFormData] = useState(InitFormData);
  const [error, setError] = useState({});
  const [inputConfirmType, setConfirmInputType] = useState("password");
  const [alternativeSite, setAlternativeSite] = useState("");

  const navigate = useNavigate();

  // On change user name
  const onChangeForm = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const getPinStatus = () => {
    makeAjax(url.USER_API.checkIfPinAlreadySet, [], url.USER_MS_EXT).then(
      (response) => {
        let config_status = response.data.get_tenant_config.value;

        if (
          config_status === true &&
          response?.data?.enabled_active_pin === true &&
          response?.data?.active_pin === false
        ) {
          navigate("/create-pin");
          return;
        } else if (
          config_status === true &&
          response?.data?.enabled_active_pin === true &&
          response?.data?.is_pin_verified === false
        ) {
          navigate("/validation-pin");
          return;
        } else if (
          response?.data?.is_security_question_set === false ||
          response?.data?.is_security_question_set === null
        ) {
          navigate("/security-question");
          return;
        } else {
          navigate("/");
          return;
        }
      }
    );
  };

  // User login
  const userLogin = (event) => {
    setError({});
    makeAjax(url.USER_API.login, formData, "").then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (
          response.code != "validation_error" &&
          response.code != "validation.credential_error"
        ) {
          showMessage(response);
          return;
        }
        setError(response.messages);
        return;
      }
      setError({});
      localStorage.setItem("user_wap_app_token", response.data.token);
      localStorage.setItem("wap_device_type", "wap");
      props.setToken();
      showMessage(response);
      getPinStatus();
    });
  };

  const showConfirmPassword = (event) => {
    if (inputConfirmType == "password") setConfirmInputType("text");
    if (inputConfirmType == "text") setConfirmInputType("password");
  };

  return (
    <>
      <div style={{ marginLeft: "10px" }}>
        <h5 style={{ marginTop: "10px" }}>{props.t("welcome")}</h5>
        <LaunguageDropdown />
        <form autoComplete="off">
          <div className="input-container">
            <label>{props.t("username")} </label>
            <input
              type="text"
              value={formData.username}
              onChange={onChangeForm}
              name="username"
              placeholder={props.t("username")}
            />
            <br />
            {error.username ? (
              <span style={{ color: "red", marginLeft: "70px" }}>
                {error.username[0]}
              </span>
            ) : (
              ""
            )}
          </div>
          <div style={{ marginTop: "7px" }}>
            <label>{props.t("password")} </label>
            <input
              type={inputConfirmType}
              value={formData.password}
              onChange={onChangeForm}
              name="password"
              placeholder={props.t("password")}
              autoComplete="new-password"
            />
            <span
              style={{
                marginTop: "-18px",
                display: "flex",
                marginLeft: "220px",
              }}
            >
              {inputConfirmType == "password" && (
                <AiOutlineEyeInvisible onClick={showConfirmPassword} />
              )}
              {inputConfirmType == "text" && (
                <BiShowAlt onClick={showConfirmPassword} />
              )}
            </span>
            {error.password ? (
              <span style={{ color: "red", marginLeft: "70px" }}>
                {error.password[0]}
              </span>
            ) : (
              ""
            )}
          </div>
          <div style={{ marginLeft: "75px" }}>
            {/* <a href="/forgot-password">{props.t('forgot_password')}</a> */}
            <NavLink to="/forgot-password">
              {props.t("forgot_password")}
            </NavLink>
          </div>
          <div>
            <input
              type={"button"}
              value={props.t("submit")}
              onClick={userLogin}
              style={{ marginLeft: "4.65%", marginTop: "1%" }}
            />
          </div>
        </form>

        <br />
        <NavLink to="/registration">{props.t("go_to_register")}</NavLink>

        <br />
        {/* <Link to={alternativeSite}>{props.t('mobile_version')}</Link> */}
      </div>
    </>
  );
};

Login.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(Login);
