import { all, fork } from "redux-saga/effects"
import playerProfileSaga from "./player-profile/saga"
import { logoutSaga } from "./logout/saga";

export default function* rootSaga() {
  yield all([
    fork(playerProfileSaga),
    fork(logoutSaga),
  ])
}

