import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
const ChangePasswordAfterLogin = props => {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        opassword: "",
        password: "",
        password_confirmation: ""
    })
    const [formError, setFormError] = useState({});
    const [valuesOld, setValuesOld] = useState({
        password: "",
        showOldPassword: false,
    });
    const [values, setValues] = useState({
        password: "",
        showPassword: false,
    });
    const [valuesConfirm, setValuesConfirm] = useState({
        password: "",
        showConfirmPassword: false,
    });
    const handleSubmit = (e) => {
        makeAjax(
            url.USER_API.changePassword,
            formData,
            url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                        return;
                    }
                    setFormError(response.messages);
                    return;
                }
                setFormError({});
                setFormData({ ...formData, ...{ opassword: '', password: '', password_confirmation: '' } });
                showMessage(response);
                navigate('/')
            });
    }
    const handleClickShowOldPassword = () => {
        setValuesOld({ ...valuesOld, showOldPassword: !valuesOld.showOldPassword });
    };
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleClickShowConfirmPassword = () => {
        setValuesConfirm({ ...valuesConfirm, showConfirmPassword: !valuesConfirm.showConfirmPassword });
    };
    const handleChange = (event) => {
        setFormData({ ...formData, [event.target.name]: event.target.value })
        setFormError({ ...formError, [event.target.name]: '' })
    }
    return (
        <div style={{ marginLeft: '10px', marginTop: '20' }}>
            <h5><NavLink to='/'>{props.t('back')}</NavLink></h5>
            <form>
                <div>
                    <label style={{ marginRight: '76px' }}>{props.t('old_password')} </label>
                    :
                    <input type={valuesOld.showOldPassword ? "text" : "password"} placeholder={props.t('old_password')} name="opassword" onChange={handleChange}
                        style={{ marginLeft: '7px', width: '200px' }}
                    />
                    <span style={{ marginTop: "-18px", display: 'flex', marginLeft: '378px' }}>
                        {valuesOld.showOldPassword == false ? (
                            <AiOutlineEyeInvisible onClick={handleClickShowOldPassword} />
                        ) :
                            <AiOutlineEye onClick={handleClickShowOldPassword} />
                        }
                    </span>
                </div>
                {formError.opassword ? (
                    <span style={{ color: 'red', marginLeft: '190px' }}>{formError.opassword[0]}</span>
                ) : null}
                <div>
                    <label style={{ marginRight: '68px' }}>{props.t('new_password')} </label>
                    :
                    <input type={values.showPassword ? "text" : "password"} placeholder={props.t('new_password')} name="password" onChange={handleChange}
                        style={{ marginLeft: '7px', marginTop: '10px', width: '200px' }}
                    />
                    <span style={{ marginTop: "-18px", display: 'flex', marginLeft: '378px' }}>
                        {values.showPassword == false ? (
                            <AiOutlineEyeInvisible onClick={handleClickShowPassword} />
                        ) :
                            <AiOutlineEye onClick={handleClickShowPassword} />
                        }
                    </span>
                </div>
                {formError.password ? (
                    <span style={{ color: 'red', marginLeft: '190px' }}>{formError.password[0]}</span>
                ) : null}
                <div>
                    <label style={{ marginRight: '6px' }}>{props.t('confirm_new_password')} </label>
                    :
                    <input type={valuesConfirm.showConfirmPassword ? "text" : "password"} placeholder={props.t('confirm_new_password')} name="password_confirmation" onChange={handleChange}
                        style={{ marginLeft: '7px', marginTop: '10px', width: '200px' }}
                    />
                    <span style={{ marginTop: "-18px", display: 'flex', marginLeft: '378px' }}>
                        {valuesConfirm.showConfirmPassword == false ? (
                            <AiOutlineEyeInvisible onClick={handleClickShowConfirmPassword} />
                        ) :
                            <AiOutlineEye onClick={handleClickShowConfirmPassword} />
                        }
                    </span>
                </div>
                {formError.password_confirmation ? (
                    <span style={{ color: 'red', marginLeft: '190px' }}>{formError.password_confirmation[0]}</span>
                ) : null}
                <div>
                    <button type='button' onClick={handleSubmit} style={{ marginLeft: '190px', marginTop: '10px' }}>{props.t('process')}</button> &nbsp;
                </div>
            </form>
        </div>
    )
}
ChangePasswordAfterLogin.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};
export default withTranslation()(ChangePasswordAfterLogin);