import axios from "axios";
import * as url from "./url_helper";
import tokenPrefix from "./jwt-token-access/token-prefix";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useNavigate } from "react-router-dom";

// Register Method
const base_url = window.location.origin;
const axiosApi = axios.create({
  baseURL: (process.env.REACT_APP_ENABLE_LOCAL_API == 1) ? process.env.REACT_APP_API_URL : base_url
});
// const token = tokenPrefix + localStorage.getItem('user_wap_app_token');
// axiosApi.defaults.headers.common["Authorization"] = token;

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
);


const showMessage = (data, toastType = null) => {

  toastr.options = {
    positionClass: 'toast-top-left',
    timeOut: 3000,
    closeButton: true,
    debug: false,
    progressBar: false,
    preventDuplicates: false,
    newestOnTop: true,
    showEasing: 'linear',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut'
    // showDuration: showDuration,
    // hideDuration: hideDuration
  };
  let message = data;
  if (data.messages)
    message = data.messages[0];
  let title = '';
  // setTimeout(() => toastr.success(`Settings updated `), 300)
  //Toaster Types
  if (!toastType)
    toastType = ((data.status == 'SUCCESS') ? 'SUCCESS' : 'error');
  if (toastType === "info") {
    title = 'Info';
    toastr.info(message, title);
  }
  else if (toastType === "warning") {
    title = 'Warning';
    toastr.warning(message, title);
  }
  else if (toastType === "error") {
    title = 'Error';
    toastr.error(message, title);
  }
  else {
    title = 'Success';
    toastr.success(message, title);
  }
}


export const respStatus = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
}

export const listDataFormat = {
  data: [],
  total: 0,
  per_page: 15,
  current_page: 1
}

export const defaultPerPage = 15;

const makeAjax = (extension, data, micro_service = url.USER_MS_EXT, header = null) => {
  // startLoader()
  let token = tokenPrefix + localStorage.getItem('user_wap_app_token');
  axiosApi.defaults.headers.common["Authorization"] = token;
  axiosApi.defaults.headers.common["tenant_sub_domain"] = localStorage.getItem('wap_user_tenant_sub_domain');
  axiosApi.defaults.headers.common["device_type"] = 'wap';
  let end_url = url.API_PREFIX + url.APPLICATION_EXT + micro_service + extension;
  if (process.env.REACT_APP_ENABLE_LOCAL_API == 2) {
    end_url = url.APPLICATION_EXT + micro_service + extension;
  }

  return axiosApi.post(end_url, data)
    .then(response => {

      if (response.status >= 200 || response.status <= 299) {
        if (response.data.status == 'ERROR') {
          if (response.data.code == 'invalid_token_error') {
           
            window.localStorage.removeItem("user_wap_app_token");
            window.location.href = base_url;
            // window.localStorage.removeItem("device_type");
          }
        }
        // stopLoader();
        return response.data;
      }
      // stopLoader();
      throw response;
    })
    .catch(err => {
      // stopLoader();
      catchAxioExc(err);
    });;
};
if (custom_loader_count === undefined)
  var custom_loader_count = 0;
const startLoader = () => {
  // let custom_loader_count = localStorage.getItem('custom_loader_count');
  if (custom_loader_count == 0) {
    var element = document.getElementById("ajax-loader");
    element.classList.add("show");
  }
  custom_loader_count++;

  // localStorage.setItem('custom_loader_count', parseInt(localStorage.getItem('custom_loader_count')) + 1);
}


const stopLoader = () => {
  //  
  // let custom_loader_count = localStorage.getItem('custom_loader_count');
  if (custom_loader_count != 0)
    custom_loader_count--;
  if (custom_loader_count == 0) {
    var element = document.getElementById("ajax-loader");
    element.classList.remove("show");
  }

  // localStorage.setItem('custom_loader_count', parseInt(localStorage.getItem('custom_loader_count')) - 1);
}

const catchAxioExc = (err) => {
  var message;
  if (err.response && err.response.status) {
    switch (err.response.status) {
      case 404:
        message = "Sorry! the API you are looking for could not be found";
        break;
      case 500:
        message =
          "Sorry! something went wrong, please contact our support team";
        break;
      case 401:
        message = "Invalid credentials";
        break;
      default:
        message = err[1];
        break;
    }
    showMessage(message, 'error');
  }
}

export {
  makeAjax,
  url,
  showMessage
};
