import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper";
import { NavLink, useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';

const GameList = props => {
    const navigate = useNavigate();
    const [gameList, setgameList] = useState(undefined);
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const receivedData = {
        category_code: params.get('category_code'),
        provider_name: params.get('provider_name'),
    };

    const listWapProviderGame = () => {
        makeAjax(
            url.USER_API.listWapProviderGame, receivedData, url.USER_MS_EXT).then(response => {

                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response);
                    return;
                }
                setgameList(response.data.gameInfo);
            });
    };

    const handleGameClick = (game) => {
        if (game.status == 'Coming Soon') {
            navigate('/comming-soon');
            return;
        }
        const payload = {
            game_id: game._id,
            is_wap: true
        };
        makeAjax(
            url.GP_API.playerRedirection, payload, url.GP_MS_EXT).then(response => {
                if (response.status != respStatus["SUCCESS"]) {
                    if (response.code == "game_under_maintenance") {
                        showMessage(`${props.t('game_under_maintenance')}`);
                        return;
                    }

                    if (response.code == "validation.invalid_token_error") {
                        navigate('/register');
                        return;
                    }

                    if (response.code != "validation_error") {
                        showMessage(response);
                        return;
                    }
                    showMessage(response);
                    return;
                }
                window.open(response.data, '_blank');
            }
            )
    };


    useEffect(() => {
        listWapProviderGame();
    }, []);
    return (
        <>
            <div>
                <h5><NavLink to='/'>{props.t('back')}</NavLink></h5>
                <h2>{props.t('game_list')}</h2>
            </div>
            {gameList != undefined &&
                <>
                    {gameList.length > 0 ?
                        <ul>
                            {
                                Object.values(gameList).map((gamingOption, index) => {
                                    return (
                                        <li key={index}>
                                            <NavLink onClick={() => { handleGameClick(gamingOption) }}> {gamingOption.name}</NavLink>
                                        </li>
                                    )
                                })
                            }
                        </ul >
                        : <h5>{props.t('no_game_found')}</h5>}
                </>
            }
        </>
    );
}

GameList.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(GameList);
