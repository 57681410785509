import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { NavLink, useNavigate } from "react-router-dom";
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper";
import TransactionQueue from "./TransactionQueue";
import { useDispatch, useSelector } from "react-redux";
import { getPlayerProfileDetail } from "../../store/player-profile/actions";
import { FiRefreshCw } from "react-icons/fi";

const Withdraw = props => {
    const navigate = useNavigate();
    const [viewData, setViewData] = useState({});
    const [formData, setFormData] = useState({});
    const [depositWithdrawNotificationClosed, setDepositWithdrawNotificationClosed] = useState(false);
    const [depositWithdrawMessage, setDepositWithdrawMessage] = useState([]);
    const [dwMessageId, setDwMessageId] = useState(null);
    const [formError, setFormError] = useState({});

    const dispatch = useDispatch();
    const { userProfile } = useSelector(state => ({
        userProfile: state.playerProfileR.data
    }));

    useEffect(() => {
        dispatch(getPlayerProfileDetail());
    }, []);


    const addWithdrawRequest = () => {
        setFormError({});
        makeAjax(url.DPWD_API.addWithdrawRequest, formData, url.DPWD_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code == 'validation_error') {
                        setFormError(response.messages);
                        return;
                    }
                    showMessage(response);
                    return;
                }
                showMessage(response.messages, 'success');
                checkPendingTransaction();
            })
    }

    const inputHandler = (event) => {
        let val = event.target.value;
        if (val != '')
            val = parseInt(val);
        if (isNaN(val)) {
            val = '';
        }
        setFormData({
            ...formData, [event.target.name]: val
        })
    }
    const checkPendingTransaction = () => {
        makeAjax(url.DPWD_API.checkPendingTransaction, {}, url.DPWD_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response.messages);
                    return;
                }
                setViewData({});
                if (response?.data.status != 'Approved' && response?.data?.transaction_type == 'withdraw') {
                    setViewData(response?.data);
                }
            })
    }

    const messageRead = () => {
        if (messageRead != null) {
            let payload = {
                _id: dwMessageId
            }
            makeAjax(
                url.DPWD_API.depositWithdrawReadMessage, payload, url.DPWD_MS_EXT).then(response => {

                    if (response.status != respStatus['SUCCESS']) {
                        showMessage(response);
                        return;
                    }
                });
        }
    }


    const depositWithdrawNotificationMessage = () => {
        makeAjax(
            url.DPWD_API.depositWithdrawNotificationMessage, [], url.DPWD_MS_EXT).then(response => {

                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response);
                    return;
                }
                setDepositWithdrawMessage(response.data);
                if (response.data.length > 0) {
                    setDwMessageId(response.data[0]._id);
                    setDepositWithdrawNotificationClosed(true);
                }
            });
    };


    useEffect(() => {
        checkPendingTransaction();
        depositWithdrawNotificationMessage();
    }, [])
    return (
        <>
            <h5><NavLink to='/'>{props.t('back_to_main_menu')}</NavLink></h5>
            <h5><NavLink to='/money'>{props.t('back')}</NavLink></h5>
            <h3>{props.t('withdraw')}</h3>
            {depositWithdrawNotificationClosed ? (<>
                {depositWithdrawMessage != undefined && depositWithdrawMessage[0]?.transaction_type == "withdraw" && depositWithdrawMessage[0]?.status == "Approved" &&
                    <>
                        <p>{props.t('we_have_success_fully_processed_your')} IDR {depositWithdrawMessage[0]?.amount} {props.t('withdraw')}.</p>
                        <p>{props.t('please_check_your_memo_for_more_information')}.</p>
                        <button onClick={() => { messageRead(depositWithdrawMessage[0]?._id); navigate('/inbox') }}>{props.t('ok')}</button>
                    </>
                }
                {depositWithdrawMessage != undefined && depositWithdrawMessage[0]?.transaction_type == "withdraw" && depositWithdrawMessage[0]?.status == "Rejected" &&
                    <>
                        <p>{props.t('your_withdraw_was_rejected')}.</p>
                        <p>{props.t('please_check_your_memo_for_more_information')}.</p>
                        <button onClick={() => { messageRead(depositWithdrawMessage[0]?._id); navigate('/inbox') }}>{props.t('ok')}</button>
                    </>
                }

                {depositWithdrawMessage != undefined && depositWithdrawMessage[0]?.transaction_type == "manual_withdraw" && depositWithdrawMessage[0]?.status == "Approved" &&
                    <>
                        <p>{props.t('your_withdrawal_funds_have_been_successfully_processed_by_us')}.</p>
                        <p>{props.t('please_check_your_memo_for_more_information')}.</p>
                        <button onClick={() => { messageRead(depositWithdrawMessage[0]?._id); navigate('/inbox') }}>{props.t('ok')}</button>
                    </>
                }
            </>) : (<>
                {
                    Object.keys(viewData).length > 0 ? (<>
                        <TransactionQueue viewData={viewData} userProfile={userProfile} checkPendingTransaction={checkPendingTransaction} />
                    </>)
                        : (<>
                            <div style={{ marginTop: '20px', marginLeft: '15px' }}>
                                <span>{props.t('available_balance')}: {userProfile?.balance}</span> &nbsp;&nbsp;
                                <span>
                                    <button onClick={() => dispatch(getPlayerProfileDetail())}><FiRefreshCw /> {props.t('refresh')}</button>
                                </span>
                                <div style={{ marginTop: '7px' }}>
                                    <span>
                                        {props.t('enter_amount')} :
                                    </span>
                                    &nbsp;&nbsp;&nbsp;
                                    <input
                                        type="text"
                                        name="amount"
                                        placeholder="00000"
                                        min={0}
                                        onChange={inputHandler}
                                    />
                                    <button onClick={() => { addWithdrawRequest() }} style={{ marginLeft: '20px' }}>{props.t('process')}</button><br />
                                    {formError.amount ? (
                                        <>
                                            <span style={{ color: 'red' }}>{formError.amount[0]}</span><br />
                                        </>
                                    ) : null}
                                    <br />
                                    <NavLink to="https://user-test.api-hkbgaming88.com/">{props.t('mobile_version')}</NavLink>
                                </div>
                            </div>
                        </>)}
            </>)}
        </>
    );
}

Withdraw.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(Withdraw);
