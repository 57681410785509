import React from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

const DepositQueue = (props) => {

    return (
        <>
            <div style={{ paddingLeft: '15px' }}>
                <h4>{props.t('deposit_inprocess')}</h4>
                <div>
                    <h3>{props.t('we_have_received_deposit_request_for')}: <span style={{ color: '#f59e0b' }}> {props?.viewData && props?.viewData.amount ? (<>{props?.viewData.amount}</>) : props?.viewData && props?.viewData.balance ? (<>{props?.viewData.balance}</>) : null}  {props?.userProfile?.currency}</span></h3>
                    <table>
                        <thead>
                            <tr>
                                <td>{props.t('bank')} / {props.t('e_wallet')}</td>
                                <td> : </td>
                                <td>
                                    {/* {props?.userProfile?.player_bank_detail && props?.userProfile?.player_bank_detail.bank_detail ?
                                        <span style={{ marginLeft: '7px' }}>{props?.userProfile?.player_bank_detail.bank_detail.bank_name}</span>
                                        : null} */}
                                    {props?.viewData && props?.viewData?.bank ? (
                                        <td className="data-b">
                                            { props?.viewData?.bank?.bank_name}
                                        </td>
                                    ) : null}
                                </td>

                            </tr>
                            <tr>
                                <td>{props.t('account_name')} / {props.t('e_wallet')}</td>
                                <td> : </td>
                                <td>
                                    {props?.userProfile?.player_bank_detail && props?.userProfile?.player_bank_detail.account_name ?
                                        <span style={{ marginLeft: '7px' }}>{props?.userProfile?.player_bank_detail.account_name}</span>
                                        : null}
                                </td>
                            </tr>
                            <tr>
                                <td> {props.t('account_number')} / {props.t('handphone_number')}</td>
                                <td> : </td>
                                <td>
                                    {props?.userProfile?.account_number}

                                </td>
                            </tr>
                        </thead>
                    </table>


                </div>
            </div>
        </>
    )
}

DepositQueue.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(DepositQueue);