import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper";
import { NavLink } from "react-router-dom";

const Memo = props => {
    const [unreadMessageCount, setUnreadMessageCount] = useState(0);

    const getUnreadMessageCount = () => {
        makeAjax(url.MEMO_API.getPlayerUnreadMessageCount, [], url.MEMO_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    showMessage(response.messages);
                    return;
                }
                setUnreadMessageCount(response.data);
            })
    }

    useEffect(() => {
        getUnreadMessageCount();
    }, [])

    return (
        <>
            <h5><NavLink to='/'>{props.t('back_to_main_menu')}</NavLink></h5>
            <ul>
                {/* <li>
                    <NavLink to='/compose-mail'>{props.t('write_a_message')}</NavLink>
                </li> */}
                <li>
                    <NavLink to='/inbox'>{props.t('inbox')} ({unreadMessageCount})</NavLink>
                </li>
                {/* <li>
                    <NavLink to='/outbox'>{props.t('outbox')}</NavLink>
                </li> */}
            </ul>

            <NavLink to="https://user-test.api-hkbgaming88.com/">{props.t('mobile_version')}</NavLink>
        </>
    )

}

Memo.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(Memo);