import React, { useEffect, useState } from "react";
import { HiOutlineMail } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
} from "../../helpers/global_helper";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getPlayerProfileDetail } from "../../store/player-profile/actions";

const ChangeEmail = (props) => {
  const InitFormData = {
    email_id: "",
    password: "",
  };
  const dispatch = useDispatch();
  const [hasEmailChange, setHasEmailChange] = useState(false);
  const [formError, setFormError] = useState({});
  const [isEmailVerified, setIsEmailVerified] = useState(false);

  const [popUpData, setPopUpData] = useState({
    email_id: "",
    please_check: "",
  });

  const [formDataChangeEmail, setFormDataChangeEmail] = useState(InitFormData);

  const { userProfile } = useSelector((state) => ({
    userProfile: state.playerProfileR.data,
  }));

  useEffect(() => {
    dispatch(getPlayerProfileDetail());
  }, []);

  const checkEmailVerified = () => {
    let verified_email = false;
    if (userProfile?.email_verified_at) verified_email = true;
    setIsEmailVerified(verified_email);
  };

  useEffect(() => {
    checkEmailVerified();
  }, [userProfile]);

  const handleChangeEmail = (event) => {
    setFormError({
      ...formError,
      [event.target.name]: '',
    });
    setFormDataChangeEmail({
      ...formDataChangeEmail,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setPopUpData({});
    setFormError({});
    makeAjax(url.USER_API.changeEmail, formDataChangeEmail, url.USER_MS_EXT).then((response) => {
      if (response.status != respStatus["SUCCESS"]) {
        if (response.code != "validation_error") {
          showMessage(response);
          return;
        }
        setFormError(response.messages);
        return;
      }
      setPopUpData(response.data);
      setHasEmailChange(true);
      setFormDataChangeEmail(InitFormData);
      dispatch(getPlayerProfileDetail());
    });
  };

  return (
    <div>

      {!hasEmailChange ? (
        <div>
          <NavLink
            to="/profile"
            onClick={() => {
              setIsEmailVerified(!isEmailVerified);
              setHasEmailChange(false);
            }}
          >
            {props.t("back")}
          </NavLink>
          <h4>{props.t("change_email")}</h4>
          <div>
            <form onSubmit={handleSubmit}>
              <div>
                <input
                  type="text"
                  name="email_id"
                  placeholder={props.t("new_email")}
                  onChange={handleChangeEmail}
                />
              </div>
              {formError.email_id ? (
                <span style={{ color: "red", marginLeft: "6px" }}>
                  {formError.email_id}
                </span>
              ) : null}
              <div>
                <input
                  type="password"
                  name="password"
                  placeholder={props.t("password")}
                  onChange={handleChangeEmail}
                />
              </div>
              {formError.password ? (
                <span style={{ color: "red", marginLeft: "6px" }}>
                  {formError.password}
                </span>
              ) : null}
              <br />
              <button type="submit">{props.t("process")}</button>
            </form>
          </div>
        </div>
      ) :
        <div>
          <div>
            <HiOutlineMail />
            <p>
              {popUpData.verification_link} <br />
              {popUpData.email_id}, {popUpData.please_check}
            </p>
            <button>
              <NavLink to="/profile">{props.t("ok")}</NavLink>
            </button>
          </div>
        </div>
      }
    </div>
  );
};

ChangeEmail.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(ChangeEmail);
