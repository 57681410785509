import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import {
  showMessage,
  makeAjax,
  url,
  respStatus,
} from "../../helpers/global_helper";
import { NavLink, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPlayerProfileDetail } from "../../store/player-profile/actions";
import { HiExclamationCircle, HiOutlineCheckCircle } from "react-icons/hi";
import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import { getLogout } from "../../store/logout/action";

const Profile = (props) => {
  const dispatch = useDispatch();
  const { userProfile } = useSelector((state) => ({
    userProfile: state.playerProfileR.data,
  }));

  const hideNumber = () => {
    if (userProfile) {
      let aNumber = userProfile?.player_bank_detail.account_number;
      let mask = aNumber.replace(/\d(?=\d{4})/g, "*");
      return mask;
    }
  };

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    opassword: "",
    password: "",
    password_confirmation: "",
  });
  const [formError, setFormError] = useState({});
  const [valuesOld, setValuesOld] = useState({
    password: "",
    showOldPassword: false,
  });
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [valuesConfirm, setValuesConfirm] = useState({
    password: "",
    showConfirmPassword: false,
  });

  const handleSubmit = (e) => {
    makeAjax(url.USER_API.changePassword, formData, url.USER_MS_EXT).then(
      (response) => {
        if (response.status != respStatus["SUCCESS"]) {
          if (response.code != "validation_error") {
            showMessage(response);
            return;
          }
          setFormError(response.messages);
          return;
        }
        setFormError({});
        setFormData({
          ...formData,
          ...{ opassword: "", password: "", password_confirmation: "" },
        });
        showMessage(response);
        dispatch(getLogout());
        navigate("/");
      }
    );
  };
  const handleClickShowOldPassword = () => {
    setValuesOld({ ...valuesOld, showOldPassword: !valuesOld.showOldPassword });
  };
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickShowConfirmPassword = () => {
    setValuesConfirm({
      ...valuesConfirm,
      showConfirmPassword: !valuesConfirm.showConfirmPassword,
    });
  };
  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    setFormError({ ...formError, [event.target.name]: "" });
  };

  const goRedirect = () => {
    if (userProfile?.enabled_active_pin) {
      let ask = window.confirm(
        "deactivating_the_pin_is_not_recommended_if_you_frequently_log_in_public_places"
      );
      if (ask) {
        handleChangeCheck();
      }
    } else {
      handleChangeCheck();
    }
  };
  const handleChangeCheck = (event) => {
    formData["enabled_active_pin"] = !userProfile?.enabled_active_pin;
    setFormData({ ...formData });
    if (userProfile?.enabled_active_pin) {
      navigate("/pin-security-validation");
    }
    if (!userProfile?.enabled_active_pin) {
      navigate("/pin-on");
    }
  };

  const changeEmail = () => {
    localStorage.setItem("email_varify_status", false);
    localStorage.setItem("change_email_status", true);
    // navigate('/change-email');
  };
  useEffect(() => {
    dispatch(getPlayerProfileDetail());
  }, []);

  return (
    <>
      <div>
        <h5>
          <NavLink to="/">{props.t("back")}</NavLink>
        </h5>
        <h2>{props.t("user_profile")}</h2>
        <div>
          <table>
            <tbody>
              <tr>
                <td>{props.t("username")}</td>
                <td> : </td>
                <td>{userProfile?.username}</td>
              </tr>
              <tr>
                <td>{props.t("email")}</td>
                <td> : </td>
                <td>
                  {userProfile?.email_verified_at ? (
                    <>
                      <HiOutlineCheckCircle
                        style={{ fontSize: "18px", color: "green" }}
                      />{" "}
                      {userProfile.email_verified_at ? (
                        <Link style={{ color: "green" }}>
                          {" "}
                          {userProfile.email_id}
                        </Link>
                      ) : (
                        userProfile.email_id
                      )}
                    </>
                  ) : (
                    <>
                      {userProfile?.email_id && (
                        <>
                          <HiExclamationCircle /> {userProfile?.email_id}{" "}
                          <NavLink to="/email-verification">
                            {props.t("verification")}
                          </NavLink>
                        </>
                      )}
                    </>
                  )}
                  {/* <a onClick={changeEmail} className="change-email-link">
                                            {props.t("change_email")}
                                        </a> */}
                  &nbsp;&nbsp;
                  <NavLink
                    to="/change-email"
                    onClick={() => {
                      changeEmail();
                    }}
                  >
                    {props.t("change_email")} ?
                  </NavLink>
                </td>
              </tr>
              <tr>
                <td>
                  {props.t("type_of_bank")} / {props.t("e_wallet")}
                </td>
                <td> : </td>
                <td>{userProfile?.player_bank_detail.bank_detail.bank_name}</td>
              </tr>
              <tr>
                <td>
                  {props.t("account_name")} / {props.t("e_wallet")}
                </td>
                <td> : </td>
                <td>{userProfile?.player_bank_detail.account_name}</td>
              </tr>
              <tr>
                <td>
                  {props.t("account_number")} / {props.t("mobile_phone")}&nbsp;
                </td>
                <td> : </td>
                <td>{hideNumber()}</td>
              </tr>
              <tr>
                <td>{props.t("last_login")}</td>
                <td> : </td>
                <td>{userProfile?.last_login_at}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <h2>{props.t('change_password')}</h2>

                <div >
                    <table>
                        <tbody>
                            <tr>
                                <td>{props.t('old_password')}</td>
                                <td> : </td>
                                <td>
                                    <input type={valuesOld.showOldPassword ? "text" : "password"} placeholder={props.t('old_password')} name="opassword" onChange={handleChange}
                                        style={{ marginLeft: '7px', width: '200px' }}
                                    />
                                    <span style={{ marginTop: "-18px", display: 'flex', marginLeft: '190px' }}>
                                        {valuesOld.showOldPassword == false ? (
                                            <AiOutlineEyeInvisible onClick={handleClickShowOldPassword} />
                                        ) :
                                            <AiOutlineEye onClick={handleClickShowOldPassword} />
                                        }
                                    </span>
                                    {formError.opassword ? (
                                        <span style={{ color: 'red', marginLeft: '6px' }}>{formError.opassword[0]}</span>
                                    ) : null}
                                </td>
                            </tr>
                            <tr>
                                <td>{props.t('new_password')}</td>
                                <td> : </td>
                                <td>
                                    <input type={values.showPassword ? "text" : "password"} placeholder={props.t('new_password')} name="password" onChange={handleChange}
                                        style={{ marginLeft: '7px', marginTop: '10px', width: '200px' }}
                                    />
                                    <span style={{ marginTop: "-18px", display: 'flex', marginLeft: '190px' }}>
                                        {values.showPassword == false ? (
                                            <AiOutlineEyeInvisible onClick={handleClickShowPassword} />
                                        ) :
                                            <AiOutlineEye onClick={handleClickShowPassword} />
                                        }
                                    </span>
                                    {formError.password ? (
                                        <span style={{ color: 'red', marginLeft: '6px' }}>{formError.password[0]}</span>
                                    ) : null}
                                </td>
                            </tr>
                            <tr>
                                <td>{props.t('confirm_new_password')}</td>
                                <td> : </td>
                                <td>
                                    <input type={valuesConfirm.showConfirmPassword ? "text" : "password"} placeholder={props.t('confirm_new_password')} name="password_confirmation" onChange={handleChange}
                                        style={{ marginLeft: '7px', marginTop: '10px', width: '200px' }}
                                    />
                                    <span style={{ marginTop: "-18px", display: 'flex', marginLeft: '190px' }}>
                                        {valuesConfirm.showConfirmPassword == false ? (
                                            <AiOutlineEyeInvisible onClick={handleClickShowConfirmPassword} />
                                        ) :
                                            <AiOutlineEye onClick={handleClickShowConfirmPassword} />
                                        }
                                    </span>
                                    {formError.password_confirmation ? (
                                        <span style={{ color: 'red', marginLeft: '6px' }}>{formError.password_confirmation[0]}</span>
                                    ) : null}
                                </td>
                            </tr>
                            <tr>
                                <td>{props.t('pin_protection')}</td>
                                <td>:</td>
                                <td>
                                    <input type="checkbox" style={{ marginTop: '10px' }} onChange={goRedirect} value={userProfile?.enabled_active_pin ? userProfile?.enabled_active_pin : false} checked={userProfile?.enabled_active_pin ? userProfile?.enabled_active_pin : false} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button type='button' onClick={handleSubmit} style={{ marginLeft: '190px', marginTop: '10px' }}>{props.t('process')}</button> &nbsp;
                </div> */}
      </div>
      <div></div>
    </>
  );
};

Profile.propTypes = {
  history: PropTypes.object,
  t: PropTypes.any,
};

export default withTranslation()(Profile);
