import React, { useState } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper";

const MailDetails = (props) => {
    const InitMessage = {
        message: '',
        subject: '',
        parent_id: '',
    };
    const [content, setContent] = useState(InitMessage);
    const [formError, setFormError] = useState({});
    let msgData = props?.msgData;
    let type = props?.type;

    const sendPlayerMessage = () => {
        content['subject'] = msgData.subject;
        content['parent_id'] = msgData._id;
        content['operator_id'] = msgData.operator_id;
        content['player_id'] = msgData.player_id;
        content['super_parent_id'] = msgData.super_parent_id;
        content['out_box_id'] = msgData.out_box_id;
        content['group_name'] = msgData.group_name;

        makeAjax(url.MEMO_API.sendPlayerMessage, content, url.MEMO_MS_EXT)
            .then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    // if (response.code == 'send_email_duration_error') {
                    //     props.emailDuration();
                    //     return;
                    // }
                    if (response.code != 'validation_error') {
                        showMessage(response);
                    }
                    setFormError(response.messages);
                    return;
                }
                setContent(InitMessage)
                showMessage(response);
            })
    }

    const handleChange = (e) => {
        setContent({ ...content, [e.target.name]: e.target.value, });
    }
    return (
        <>
            <p>
                <span>
                    {props.t('from')}
                </span>
                <span style={{ marginLeft: '100px' }}>
                    : {msgData.domain_name}
                </span>
            </p>
            <p>
                <span>
                    {props.t('date')} / {props.t('time')}
                </span>
                <span style={{ marginLeft: '55px' }}>
                    : {msgData.created_at}
                </span>
            </p>
            <p>
                <span>
                    {props.t('subject')}
                </span>
                <span style={{ marginLeft: '88px' }}>
                    : {msgData.subject}
                </span>
            </p>
            <p>
                <span>
                    {props.t('description')}
                </span>
                <span style={{ marginLeft: '58px' }}>
                    : <div dangerouslySetInnerHTML={{ __html: msgData.message }} style={type == 'inbox' ? { marginLeft: '145px', marginTop: '-35px' } : { marginLeft: '145px', marginTop: '-20px' }}></div>
                </span>
            </p>
           
        </>
    )
}
MailDetails.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(MailDetails);
