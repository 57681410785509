import { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';

const InitPin = (props) => {

    const navigate = useNavigate();
    const { userProfile } = useSelector(state => ({
        userProfile: state.playerProfileR.data,
    }));

    const InitFormData = {
        pin: '',
        pin_confirmation: '',
        type: 'pin-on'
    }


    const [formData, setFormData] = useState(InitFormData)
    const [formError, setFormError] = useState({});

    const handleSubmit = (e) => {
        e.preventDefault();
        makeAjax(
            url.USER_API.setUserNewPin, formData, url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error')
                        showMessage(response);
                    setFormError(response.messages);
                    return;
                }
                showMessage(response);
                navigate('/profile');
                setFormError({});
                setFormData({ ...formData, ...{ pin: '', pin_confirmation: '' } });
            });
    }

    const back = () => {
        navigate('/profile');
    };

    const inputHandler = (event) => {
        let val = event.target.value;
        if (val != '')
            val = parseInt(val);
        if (isNaN(val)) {
            val = '';
        }
        setFormData({
            ...formData, [event.target.name]: val.toString()
        })
    }

    return (
        <div>
            <div>
                <h4>{props.t('welcome')}, {userProfile?.username}</h4>
                <div>
                    <h4>{props.t('enter_your_six_digits_pin')}</h4>
                    <p>{props.t('this_system_is_to_increase_security_and_privacy_of_your_account')}</p>
                    <div>
                        <form>
                            <div>
                                <input type="password" name="pin" placeholder={props.t('enter_your_new_pin')} value={formData.pin} onChange={inputHandler} maxLength={6}
                                    pattern="[^\s]+" />
                                <br />
                                {formError.pin ? (
                                    <span style={{ color: 'red' }}>{formError.pin}</span>
                                ) : null}

                            </div>
                            <div style={{ marginTop: '5px' }}>
                                <input type="password" placeholder={props.t('confirm_new_pin')} name="pin_confirmation" value={formData.pin_confirmation} onChange={inputHandler} maxLength={6}
                                    pattern="[^\s]+" />
                                <br />
                                {formError.pin_confirmation ? (
                                    <span style={{ color: 'red' }}>{formError.pin_confirmation}</span>
                                ) : null}
                            </div>
                            <button type='button' onClick={(e) => { handleSubmit(e) }} style={{ marginTop: '5px' }}>{props.t('process')}</button> &nbsp;&nbsp;&nbsp;&nbsp;
                            <button type='button' onClick={back} style={{ marginTop: '5px' }}>{props.t('out')}</button>
                        </form>
                    </div>
                    <span>{props.t('this_pin_will_be_used_every_time_you_enter_the_game')}</span>
                </div>
            </div>
        </div>
    )
}

InitPin.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(InitPin);