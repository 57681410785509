import { combineReducers } from "redux";
import playerProfileR from './player-profile/reducer';
import logoutR from "./logout/reducer";

const rootReducer = combineReducers({
  playerProfileR,  
  logoutR,
})

export default rootReducer
