import React, { useState, useEffect } from 'react'
import { Row } from "reactstrap";
import PropTypes from 'prop-types';
import { withTranslation } from "react-i18next";
import { NavLink, useNavigate } from 'react-router-dom';
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper";
import { useDispatch, useSelector } from "react-redux";
import { getPlayerProfileDetail } from "../../store/player-profile/actions";
import { getLogout } from '../../store/logout/action';


const SecurityPin = props => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { userProfile } = useSelector(state => ({
        userProfile: state.playerProfileR.data,
    }));


    const InitFormData = {
        active_pin: '',
        type: 'security-login'
    }

    const [isChecked, setIsChecked] = useState(true);
    const [formData, setFormData] = useState(InitFormData);
    const [formError, setFormError] = useState({});


    const getPinStatus = () => {
        makeAjax(
            url.USER_API.checkIfPinAlreadySet, [], url.USER_MS_EXT).then(response => {
                let config_status = response.data.get_tenant_config.value;

                if (config_status === true) {

                    if (response?.data?.enabled_active_pin === true && response?.data?.is_pin_verified === false) {
                        // if (response.data.active_pin === '' || response.data.active_pin === null && response.data.enabled_active_pin === true) {
                        navigate("/create-pin");
                        return;
                    }
                    else if (response?.data?.enabled_active_pin === true && response?.data?.active_pin === false) {
                        navigate("/validation-pin");
                        return;
                    }
                    else if ((response?.data?.is_security_question_set === false || response?.data?.is_security_question_set === null)) {
                        navigate("/security-question");
                        return;
                    }
                    else {
                        navigate("/");
                        return;
                    }
                } else {
                    navigate("/");
                }
            });
    }

    const hideNumber = () => {
        if (userProfile) {
            let aNumber = userProfile?.player_bank_detail.account_number;
            let mask = aNumber.replace(/\d(?=\d{4})/g, "*");
            return mask;
        }
    }

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        setFormError({});
        if (!isChecked) {
            showMessage(`${props.t('the_rules_of_play_have_not_been_approved')}`);
            return;
        }
        makeAjax(
            url.USER_API.checkValidatePin, formData, url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error') {
                        showMessage(response['messages']);
                        window.localStorage.removeItem("user_wap_app_token");
                        return;
                    }
                    setFormError(response.messages);
                    setFormData(InitFormData);
                    return;
                }
                setFormError({});
                setFormData({ ...formData, ...{ active_pin: '' } });
                getPinStatus();
            });

    }

    const logout = () => {
        dispatch(getLogout());
        navigate('/');
    }


    const inputHandler = (event) => {
        let val = event.target.value;
        if (val != '')
            val = parseInt(val);
        if (isNaN(val)) {
            val = '';
        }
        setFormData({
            ...formData, [event.target.name]: val.toString()
        })
    }

    useEffect(() => {
        dispatch(getPlayerProfileDetail());
    }, []);
    return (
        <>
            <div style={{ marginLeft: '15px' }}>
                <h5>{props.t('welcome')} {userProfile?.username}</h5>
                <h4>{props.t('security_validation')}</h4>
                <h5>{props.t('check_and_make_sure_your_data_is_correct')}</h5>

                <div >
                    <table>
                        <tbody>
                            <tr>
                                <td>{props.t('username')}</td>
                                <td> : </td>
                                <td>{userProfile?.username}</td>
                            </tr>
                            <tr>
                                <td>{props.t('nickname')}</td>
                                <td> : </td>
                                <td>{userProfile?.nickname}</td>
                            </tr>
                            <tr>
                                <td>{props.t('type_of_bank')} / {props.t('e_wallet')}</td>
                                <td> : </td>
                                <td>{userProfile?.player_bank_detail.bank_detail.bank_name}</td>
                            </tr>
                            <tr>
                                <td>{props.t('account_name')} / {props.t('e_wallet')}</td>
                                <td> : </td>
                                <td>{userProfile?.player_bank_detail.account_name}</td>
                            </tr>
                            <tr>
                                <td>{props.t('account_number')} / {props.t('mobile_phone')}&nbsp;</td>
                                <td> : </td>
                                <td>{hideNumber()}</td>
                            </tr>
                            <tr>
                                <td>{props.t('last_login')}</td>
                                <td> : </td>
                                <td>{userProfile?.last_login_at}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <input type="checkbox" id="agree" checked={isChecked} onChange={handleCheckboxChange} />
                    <label htmlFor="agree">{props.t('agree_about')}<NavLink to="/rules-of-play">{props.t('rules_of_play')}</NavLink></label>
                </div>
                <h5>{props.t('please_check_personal_data_before_entering_the_pin')}</h5>
                <div>
                    <p>{props.t('user_id_will_be_blocked_if_you_experience_five_x_errors')}</p>
                    <p>{props.t('if_you_forget_your_pin_please_click_contact_our_operator')}</p>
                    <p>{props.t('do_not_share_your_pin_with_others')}</p>
                </div>
                <div>
                    <form>
                        <div>
                            <input type="password" name='active_pin' placeholder={props.t('enter_your_pin')} value={formData.active_pin} onChange={inputHandler} maxLength={6}
                                pattern="[^\s]+" />
                            <button type='button' onClick={handleSubmit} style={{ marginLeft: '25px' }}>{props.t('proceed')}</button>
                        </div>
                        {formError.pin_failed_attempt ? (
                            <span style={{ color: 'red' }}>{formError.pin_failed_attempt}</span>
                        ) : null}
                        {formError.active_pin ? (
                            <span style={{ color: 'red' }}>{formError.active_pin}</span>
                        ) : null}
                        <br />
                        <button type='button' onClick={logout} style={{ marginTop: '15px' }}>{props.t('logout')}</button>
                    </form>
                    <span>{props.t('forget_your_pin')} <a href="#">{props.t('contact_our_support')}</a></span>
                </div>
            </div>
        </>
    )

}
SecurityPin.propTypes = {
    history: PropTypes.object,
    t: PropTypes.any,
};

export default withTranslation()(SecurityPin);

