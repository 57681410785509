import { makeAjax, showMessage, url } from "../../helpers/global_helper";
import { USER_API } from "../../helpers/url_helper";
import { getLogoutSuccess, getLogout } from "./action";
import { GET_LOGOUT } from "./actionType";
import { call, put, all, takeEvery } from "redux-saga/effects";

function* logout() {
    try {
        const response = yield call(makeAjax, url.USER_API.logout, [], '');
        if (response.status === 'SUCCESS') {
            yield all([
                put(getLogoutSuccess({ response: response.data })),
            ]);
            window.location.reload();
            localStorage.removeItem("user_wap_app_token");

            // // Dispatch an action indicating successful logout
            // yield put(getLogout());

        } else {
            showMessage(response);
        }
    } catch (error) {
        console.error(error);
        showMessage(error);
    }
}

export function* logoutSaga() {
    yield takeEvery(GET_LOGOUT, logout);
}