import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Register from "./pages/authPages/Register";
import CreatePin from "./pages/authPages/CreatePin";
import Go from "./pages/Go";
import Dashboard from "./pages/commonPages/Dashboard";
import SecurityPin from "./pages/authPages/SecurityPin";
import SecurityQuestion from "./pages/authPages/SecurityQuestion";
import Memo from "./pages/Memo/Memo";
import Inbox from "./pages/Memo/Inbox";
import RulesOfPlay from "./pages/authPages/RuleOfPlay";
import MailDeatails from "./pages/Memo/MailDeatails";
import Cashier from "./pages/Cashier/Cashier";
import Withdraw from "./pages/Cashier/Withdraw";
import TransactionQueue from "./pages/Cashier/TransactionQueue";
import ChangePasswordAfterLogin from "./pages/ChangePasswordAfterLogin/ChangePasswordAfterLogin";
import Deposit from "./pages/Cashier/Deposit";
import DepositQueue from "./pages/Cashier/DepositQueue";
import GameList from "./pages/GameList/GameList";
import Login from "./pages/authPages/Login";
import Profile from "./pages/Profile/Profile";
import CommingSoon from "./pages/CommingSoon/CommingSoon";
import PinSecurityValidation from "./pages/Profile/PinSecurityValidation";
import InitPin from "./pages/Profile/InitPin";
import ForgotPassword from "./pages/authPages/ForgotPassword";
import ProviderList from "./pages/GameList/ProviderList";
import ChangeEmail from "./pages/Profile/ChangeEmail";
import EmailVerification from "./pages/Profile/EmailVerification";

export default function App() {
  const [isLogin, setIsLogin] = useState(false);
  const setToken = () => {
    setIsLogin(true);
  }
  useEffect(() => {
    const token = localStorage.getItem("user_wap_app_token");
    setIsLogin(!!token);
  }, []);


  return (
    <Routes>
      {isLogin ? (
        <>
          <Route index element={<Dashboard />} />
          <Route path="create-pin" element={<CreatePin />} />
          <Route path="validation-pin" element={<SecurityPin />} />
          <Route path="rules-of-play" element={<RulesOfPlay />} />
          <Route path="security-question" element={<SecurityQuestion />} />
          <Route path="messages" element={<Memo />} />
          <Route path="inbox" element={<Inbox />} />
          <Route path="mail-details" element={<MailDeatails />} />
          <Route path="money" element={<Cashier />} />
          <Route path="withdraw" element={<Withdraw />} />
          <Route path="deposit" element={<Deposit />} />
          <Route path="transaction-queue" element={<TransactionQueue />} />
          <Route path="change-password-after-login" element={<ChangePasswordAfterLogin />} />
          <Route path="deposit-queue" element={<DepositQueue />} />
          <Route path="game-list" element={<GameList />} />
          <Route path="provider-list" element={<ProviderList />} />
          <Route path="profile" element={<Profile />} />
          <Route path="change-email" element={<ChangeEmail />} />
          <Route path="email-verification" element={<EmailVerification />} />
          <Route path="pin-security-validation" element={<PinSecurityValidation />} />
          <Route path="pin-on" element={<InitPin />} />
          <Route path="comming-soon" element={<CommingSoon />} />
        </>
      ) : (
        <>
          <Route path="/" element={<Login setToken={setToken} />} />
          <Route path="registration" element={<Register setToken={setToken} />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
        </>
      )}
      <Route path="go/:subDomain" element={<Go />} />
    </Routes>
  );
}
