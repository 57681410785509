import { GET_LOGOUT_SUCCESS } from "./actionType";

const initialState = {
    data: {},
}

const logoutR = (state = initialState, action) => {
    switch (action.type) {
        case GET_LOGOUT_SUCCESS:
            return { ...state, data: action.payload.response };

        default:
            return state;
    }
}

export default logoutR