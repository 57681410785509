import { useState } from 'react'
import { showMessage, makeAjax, url, respStatus } from "../../helpers/global_helper";
import { useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { getPlayerProfileDetail } from "../../store/player-profile/actions";
import { useDispatch, useSelector } from "react-redux";

const PinSecurityValidation = (props) => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { userProfile } = useSelector(state => ({
        userProfile: state.playerProfileR.data,
    }));

    const InitFormData = {
        active_pin: '',
        type: 'security-profile'
    }

    const [formData, setFormData] = useState(InitFormData);
    const [formError, setFormError] = useState({});


    const handleSubmit = (e) => {
        e.preventDefault()
        makeAjax(
            url.USER_API.checkValidatePin, formData, url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    if (response.code != 'validation_error')
                        showMessage(response);
                    setFormError(response.messages);
                    return;
                }
                setFormError({});
                setFormData({ ...formData, ...{ active_pin: '' } });
                showMessage(response);
                pinDisable();
            });
    }

    const pinDisable = () => {
        makeAjax(
            url.USER_API.updatePinStatus, { enabled_active_pin: false }, url.USER_MS_EXT).then(response => {
                if (response.status != respStatus['SUCCESS']) {
                    showMessage(response);
                    return;
                }
                showMessage(response);
                navigate("/profile");
            });
    }


    const back = () => {
        dispatch(getPlayerProfileDetail());
        navigate('/profile');
    }

    const inputHandler = (event) => {
        let val = event.target.value;
        if (val != '')
            val = parseInt(val);
        if (isNaN(val)) {
            val = '';
        }
        setFormData({
            ...formData, [event.target.name]: val.toString()
        })
    }

    return (
        <div>
            <h4>{props.t('welcome')},{userProfile?.username}</h4>
            <div>
                <h4>{props.t('security_validation')}</h4>
                <p>{props.t('aims_to_increase_the_security_and_privacy_of_your_account')}</p>
                <form>
                    <div>
                        <input type="password" name='active_pin' placeholder={props.t('enter_your_pin')} value={formData.active_pin} onChange={inputHandler} maxLength={6}
                            pattern="[^\s]+" />
                        <button type='button' onClick={handleSubmit} style={{ marginLeft: '25px' }}>{props.t('proceed')}</button>
                    </div>
                    {formError.pin_failed_attempt ? (
                        <span style={{ color: 'red' }}>{formError.pin_failed_attempt}</span>
                    ) : null}
                    {formError.active_pin ? (
                        <span style={{ color: 'red' }}>{formError.active_pin}</span>
                    ) : null}
                    <br />
                    <button type='button' onClick={back} style={{ marginTop: '15px' }}>{props.t('back')}</button>
                </form>
                <span>{props.t('forgot_pin')} ? <a href='#'>{props.t('contact_our_operator')}</a></span>
            </div>
        </div>
    )
}

PinSecurityValidation.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default withTranslation()(PinSecurityValidation);